import { ref, onMounted, watch } from "vue";

export function useModal(modalRef, open = false) {
  const isOpen = ref(open);
  const toggleModal = (open) => {
    if (modalRef.value) {
      open ? modalRef.value.$el.showModal() : modalRef.value.$el.close();
    }
  };
  const openModal = () => {
    isOpen.value = true;
  };
  const closeModal = () => {
    isOpen.value = false;
  };
  onMounted(() => {
    toggleModal(isOpen.value);
  });
  watch(
    () => isOpen.value,
    (value) => {
      toggleModal(value);
    }
  );
  return [openModal, closeModal, isOpen];
}
