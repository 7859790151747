<template>
  <StModal :on-close="() => $emit(CLOSE)">
    <div class="st-bo-admin-info-modal">
      <div class="st-bo-admin-info-modal__header">
        <div class="st-bo-admin-info-modal__header__title">
          <StHeadline :level="2"> {{ admin.givenName }}s konton </StHeadline>
        </div>
        <div class="st-bo-admin-info-modal__header__exit-icon">
          <StIcon
            name="st-close-big"
            role="presentation"
            @click="$emit(CLOSE)"
          />
        </div>
      </div>
      <div class="st-bo-admin-info-modal__row">
        <StHeadline :level="3" class="st-bo-admin-info-modal__row__title">
          Administratörsdetaljer
        </StHeadline>
        <status
          :status="!admin.blocked"
          :title="admin.blocked ? 'Spärrad' : 'Aktiv'"
        />
      </div>
      <div class="st-bo-admin-info-modal__row" v-if="!isNotAdmin">
        <p>
          {{
            accountAdmin
              ? `Denna vy tillåter dig att redigera uppgifterna för den enskilda administratören kopplat till ${
                  account.accountName ? account.accountName : "valt konto"
                }.`
              : "Denna vy tillåter dig att redigera uppgifterna för samtliga administratörer som matchar vald kombination av personnummer, email samt mobilnummer."
          }}
        </p>
      </div>
      <div class="st-bo-admin-info-modal__row">
        <div class="st-bo-admin-info-modal__row__email">
          <st-form-group
            label="Mejladress"
            required
            :validation="v$.email"
            :validation-errors="validationErrors.email"
          >
            <st-input
              id="admin-info-modal-input-mail"
              v-model="adminDetails.email"
              :disabled="!editInfo"
              tabindex="0"
              :invalid="v$ && v$.email && v$.email.$error"
              :blur="() => handleBlur('email')"
            />
          </st-form-group>
        </div>
        <div class="st-bo-admin-info-modal__row__phonenumber">
          <st-form-group
            label="Mobilnummer"
            required
            :validation="v$.phoneNumber"
            :validation-errors="validationErrors.phoneNumber"
          >
            <st-input
              id="admin-info-modal-input-phone"
              v-model="adminDetails.phoneNumber"
              :disabled="!editInfo"
              tabindex="0"
              :invalid="v$ && v$.phoneNumber && v$.phoneNumber.$error"
              :blur="() => handleBlur('phoneNumber')"
            />
          </st-form-group>
        </div>
      </div>
      <div class="st-bo-admin-info-modal__row">
        <st-input
          id="admin-info-modal-input-firstname"
          v-model="admin.givenName"
          :label-name="'Förnamn'"
          tabindex="0"
          disabled
        />
        <st-input
          id="admin-info-modal-input-surname"
          v-model="admin.surname"
          :label-name="'Efternamn'"
          tabindex="0"
          disabled
        />
      </div>
      <div class="st-bo-admin-info-modal__ssn">
        <div class="st-bo-admin-info-modal__ssn__input">
          <st-input
            id="admin-info-modal-input-ssn"
            v-model="admin.ssn"
            :label-name="'Personnummer'"
            disabled
            tabindex="0"
          />
        </div>
        <div class="st-bo-admin-info-modal__ssn__btn">
          <st-button
            tabindex="0"
            primary
            @click="$emit('handle-block')"
            :disabled="isNotAdmin"
          >
            {{ admin.blocked ? "Aktivera" : "Spärra" }} personnummer
          </st-button>
        </div>
      </div>
      <div class="st-bo-admin-info-modal__buttons">
        <st-button
          primary
          tabindex="0"
          @click="handleUpdate"
          :disabled="isNotAdmin"
          :is-loading="saveLoading"
        >
          {{ editInfo ? "Spara uppgifter" : "Ändra uppgifter" }}
        </st-button>
        <st-information-box
          class="st-bo-admin-info-modal__buttons__feedback"
          v-if="detailsSaved.show"
          :information-type="detailsSaved.success ? 'success' : 'failure'"
          small
        >
          {{ detailsSaved.text }}
        </st-information-box>
      </div>
      <div class="st-bo-admin-info-modal__table">
        <div class="st-bo-admin-info-modal__table__title">
          <StHeadline :level="3">Konton</StHeadline>
        </div>
        <st-table
          :headers="headers"
          :table-data="adminAccounts"
          @on-item-click="deleteClick"
          @on-row-click="rowClick"
          clickable
        />
      </div>
    </div>
  </StModal>
</template>

<script>
import {
  StModal,
  StInput,
  StButton,
  StTable,
  StFormGroup,
  StInformationBox,
  StHeadline,
} from "skanetrafiken";
import { useAdmin } from "@/composables/useAdmin";
import { useUser } from "@/composables/useUser";
import { useTable } from "@/composables/useTable";
import { useAccount } from "@/composables/useAccount";
import { useError } from "@/composables/useError";
import { ADMIN_ACCOUNTS, CLOSE, GENERIC_ERROR_MSG } from "@/constants";
import { computed, ref, watch } from "vue";
import { StIcon } from "skanetrafiken";
import Status from "@/components/common/Status.vue";
import router from "@/router";
import ChangeAdminValidations from "@/validators/change-admin-validations";
import ChangeAdminValidationsErrors from "@/validators/change-admin-validations-errors";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "st-bo-admin-info-modal",
  components: {
    StModal,
    StInput,
    StButton,
    StTable,
    StIcon,
    StInformationBox,
    StFormGroup,
    StHeadline,
    Status,
  },
  props: {
    accountAdmin: Boolean,
  },
  setup(props, { emit }) {
    const admin = useAdmin();
    const account = useAccount();
    const user = useUser();
    const table = useTable();
    const errorMessages = useError();
    const detailsSaved = ref({ show: false, text: "", success: false });
    const editInfo = ref(false);
    const saveLoading = ref(false);
    const adminDetails = ref({ email: "", phoneNumber: "" });
    const v$ = useVuelidate(ChangeAdminValidations, adminDetails);

    watch(
      () => admin.state.admin,
      () => {
        adminDetails.value = {
          email: admin.state.admin.email,
          phoneNumber: admin.state.admin.phoneNumber,
        };
      },
      { deep: true }
    );

    const handleUpdate = async () => {
      if (editInfo.value) {
        v$.value.$touch();
        const errors = v$.value.$errors;
        if (errors.length) return;
        detailsSaved.value.success = false;
        detailsSaved.value.show = false;
        saveLoading.value = true;
        try {
          await admin.updateAdminInfo(
            adminDetails.value,
            account.state.account.accountId,
            props.accountAdmin
          );
          saveLoading.value = false;
          editInfo.value = false;
          detailsSaved.value.success = true;
          showUserFeedback("Uppgifter sparades");
        } catch (err) {
          saveLoading.value = false;
          editInfo.value = false;
          showUserFeedback(
            errorMessages.getErrorMessage(err.response.data.code || err.code) ||
              GENERIC_ERROR_MSG
          );
        }
      } else {
        editInfo.value = true;
      }
    };

    const showUserFeedback = (text) => {
      detailsSaved.value = {
        show: true,
        text,
        success: detailsSaved.value.success,
      };
      if (detailsSaved.value.success) {
        setTimeout(() => {
          detailsSaved.value.show = false;
        }, 3000);
      }
    };

    const deleteClick = (index) => {
      if (user.state.isAdmin) {
        admin.setSelectedAdminAccount(admin.state.admin.accounts[index]);
        emit("handle-remove");
      }
    };

    const rowClick = async (_, index) => {
      router
        .push(`/account-details/${admin.state.admin.accounts[index].accountId}`)
        .then(() => window.location.reload());
    };

    const handleBlur = (prop) => {
      v$.value[prop].$touch();
    };

    return {
      admin: computed(() => admin.state.admin),
      headers: table.getTableHeaders(ADMIN_ACCOUNTS),
      adminAccounts: computed(() =>
        admin.state.admin.accounts.map((account) => account.tableInfo)
      ),
      handleUpdate,
      deleteClick,
      isNotAdmin: computed(() => !user.state.isAdmin),
      detailsSaved,
      rowClick,
      account: computed(() => account.state.account),
      saveLoading,
      v$,
      handleBlur,
      validationErrors: ChangeAdminValidationsErrors,
      editInfo,
      adminDetails,
      CLOSE,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-admin-info-modal {
  display: flex;
  flex-direction: column;
  font-family: $source-sans-pro;

  &__header {
    display: flex;
    justify-content: center;
    margin-bottom: rem-calc(15);

    &__title {
      width: 100%;
      text-align: center;
      margin-bottom: rem-calc(16);
    }

    &__exit-icon {
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: rem-calc(16);

    @include large {
      flex-direction: row;

      & > :not(:last-child) {
        margin-right: rem-calc(15);
      }

      &__email {
        width: 100%;
      }

      &__phonenumber {
        width: 100%;
      }
    }

    &__title {
      font-size: rem-calc(24);
    }
  }

  &__ssn {
    display: flex;
    flex-direction: column;

    &__btn {
      margin-top: rem-calc(20);
      width: 100%;
      align-self: end;
    }

    &__input {
      width: 100%;
      margin-right: rem-calc(15);
    }

    @include large {
      flex-direction: row;
    }
  }

  &__buttons {
    margin-top: rem-calc(30);
    display: flex;
    flex-direction: column;

    & > :first-child {
      margin-bottom: rem-calc(15);
    }

    @include large {
      flex-direction: row;

      & > :first-child {
        margin-top: 0;
        margin-bottom: 0;
        margin-right: rem-calc(10);
      }
    }

    &__feedback {
      padding: rem-calc(8) rem-calc(12);
    }
  }

  &__table {
    text-align: start;
    margin-top: rem-calc(16);
    border-top: rem-calc(1) solid $dark-grey;

    &__title {
      padding-top: rem-calc(8);
      font-size: rem-calc(24);
    }
  }
}
</style>
