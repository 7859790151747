<template>
  <div v-if="isLoading.account" class="st-bo-account-details-view__spinner">
    <st-spinner loading-message="Laddar..." />
  </div>
  <error
    v-else-if="error.text"
    class="st-bo-account-details-view__error-msg"
    :text="error.text"
    :error-message="error.errMessage"
    :error-code="error.errCode"
  />
  <div v-else class="st-bo-account-details-view">
    <div class="st-bo-account-details-view__title">
      <StHeadline>{{ pageTitle }}</StHeadline>
    </div>
    <account-details :is-disabled="disabled" />
    <account-tab-bar :is-disabled="disabled" :only-billing="isLocked" />
  </div>
</template>

<script>
import AccountDetails from "@/components/AccountDetails.vue";
import AccountTabBar from "@/components/AccountTabBar.vue";
import Error from "@/components/common/Error.vue";
import { useAccount } from "@/composables/useAccount";
import { useUser } from "@/composables/useUser";
import { useError } from "@/composables/useError";
import { onBeforeMount, onMounted, ref, reactive, computed } from "vue";
import { StSpinner, StHeadline } from "skanetrafiken";
import { ACCOUNT_APPLICATION, ACCOUNT_VERIFICATION } from "@/constants";

export default {
  name: "st-bo-account-details-view",
  components: {
    AccountDetails,
    AccountTabBar,
    StSpinner,
    StHeadline,
    Error,
  },
  props: {
    accountId: String,
    accountType: String,
    isLocked: Boolean,
  },
  setup(props) {
    const account = useAccount();
    const user = useUser();
    const errorMessages = useError();
    const error = ref({ text: "", errMessage: "", errCode: "" });

    const isLoading = reactive({
      account: false,
    });

    onBeforeMount(() => {
      account.setSelectedAccount(props.accountId);
    });

    onMounted(async () => {
      try {
        isLoading.account = true;
        if (props.accountType === ACCOUNT_VERIFICATION) {
          await account.getAccountVerificationDetails(props.accountId);
        } else if (props.accountType === ACCOUNT_APPLICATION) {
          await account.getAccountApplicationDetails(props.accountId);
        } else {
          await account.getAccountDetails(props.accountId);
        }
        isLoading.account = false;
      } catch (err) {
        isLoading.account = false;
        error.value = {
          text: "Valt konto kunde inte hämtas. Vänligen prova igen senare.",
          errMessage: errorMessages.getErrorMessage(
            err.response.data.code || err.code
          ),
          errCode: err.response.data.code || err.code,
        };
      }
    });

    const pageTitle = computed(() => {
      const titles = {
        createdAccount: "Konto",
        accountApplication: "Kontoansökan",
        accountVerification: "Kontoverifiering",
      };
      return titles[props.accountType];
    });

    return {
      isLoading: computed(() => isLoading),
      error,
      disabled: computed(() => props.isLocked || !user.state.isAdmin),
      pageTitle,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-account-details-view {
  padding: rem-calc(20);

  &__title {
    text-align: start;
  }

  & > :not(:last-child) {
    margin-bottom: rem-calc(32);
  }

  &__spinner {
    margin-top: 20vh;
  }

  &__error-msg {
    margin-top: 20vh;
  }
}
</style>
