<template>
  <StModal :on-close="handleClose">
    <div class="st-domain-add-modal">
      <StHeadline :level="2"> Lägg till vitlistning</StHeadline>
      <template v-if="inConfirm">
        <div class="st-domain-add-modal__confirm">
          <p>Är du säker på att du vill vitlista domänen för organisationen?</p>
          <ModalDomainDetails
            :domain="inputs.domain"
            :organization-number="inputs.organizationNumber"
            :organization-name="inputs.organizationName"
          />
        </div>
      </template>
      <template v-else>
        <div class="st-domain-add-modal__inputs">
          <StFormGroup
            label="Domän"
            for-id="inputDomain"
            required
            description="Domänen måste börja med @ och avslutas med toppdomänen, t.ex .se eller .com"
            :validation="v$.domain"
            :validation-errors="AddDomainValidationsErrors.domain"
          >
            <StInput
              id="inputDomain"
              v-model="inputs.domain"
              required
              :invalid="v$ && v$.domain && v$.domain.$error"
              :blur="() => handleBlur('domain')"
            />
          </StFormGroup>
          <StFormGroup
            label="Organisationsnummer"
            for-id="inputOrganization"
            required
            description="Organisationsnumret måste innehålla 10 siffror"
            :validation="v$.organizationNumber"
            :validation-errors="AddDomainValidationsErrors.organizationNumber"
          >
            <StInput
              id="inputOrganization"
              v-model="inputs.organizationNumber"
              required
              :invalid="
                v$ && v$.organizationNumber && v$.organizationNumber.$error
              "
              :blur="() => handleBlur('organizationNumber')"
            >
              <transition name="fade">
                <StSpinner
                  v-if="inputs.loadingOrganization"
                  small
                  loading-message="Söker efter organisationen"
                />
              </transition>
            </StInput>
          </StFormGroup>
          <transition name="fade">
            <StInformationBox v-if="inputs.loadingOrganization">
              Söker efter organisationen...
            </StInformationBox>
          </transition>
        </div>
      </template>
      <div class="st-domain-add-modal__buttons">
        <st-button full-width @click="handleBack">
          {{ inConfirm ? "Tillbaka" : "Avbryt" }}
        </st-button>
        <st-button
          primary
          full-width
          @click="handleNext"
          :is-loading="isLoading"
        >
          {{ inConfirm ? "Lägg till vitlistning" : "Fortsätt" }}
        </st-button>
      </div>
      <StError v-if="errorMessage" :error-message="errorMessage" />
    </div>
  </StModal>
</template>

<script setup>
import {
  StModal,
  StButton,
  StHeadline,
  StError,
  StInput,
  StFormGroup,
  StInformationBox,
  StSpinner,
} from "skanetrafiken";
import { CLOSE } from "@/constants";
import { ref, watch } from "vue";
import { useVuelidate } from "@vuelidate/core";
import AddDomainValidations from "@/validators/add-domain-validations";
import AddDomainValidationsErrors from "@/validators/add-domain-validations-errors";
import ModalDomainDetails from "../common/ModalDomainDetails.vue";

const emit = defineEmits(["create", "close", "on-back"]);

const props = defineProps({
  isLoading: Boolean,
  errorMessage: String,
  isOpen: Boolean,
});

const inputs = ref({
  domain: "@",
  organizationNumber: "",
  organizationName: "",
  loadingOrganization: false,
});

const v$ = useVuelidate(AddDomainValidations, inputs);

const inConfirm = ref(false);

const handleBlur = (key) => {
  v$.value[key].$touch();
};

const handleNext = () => {
  if (!inConfirm.value) {
    inputs.value.organizationName
      ? v$.value.domain.$touch()
      : v$.value.$touch();
    const errors = v$.value.$errors;
    if (errors.length) return;
    inConfirm.value = true;
  } else {
    emit("create", inputs.value);
  }
};

const handleBack = () => {
  if (inConfirm.value) {
    inConfirm.value = false;
    emit("on-back");
  } else {
    handleClose();
  }
};

const handleClose = () => {
  emit(CLOSE);
  inputs.value = {
    domain: "@",
    organizationNumber: "",
    organizationName: "",
    loadingOrganization: false,
  };
  inConfirm.value = false;
  v$.value.$reset();
};

watch(
  () => props.isOpen,
  (isOpen) => {
    if (!isOpen) {
      handleClose();
    }
  }
);
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/functions.scss";
@import "~skanetrafiken/dist/scss/breakpoints.scss";
.st-domain-add-modal {
  text-align: start;
  font-family: var(--font-primary);

  @include large {
    width: rem-calc(500);
  }

  & > :first-child {
    text-align: center;
  }

  & > :not(:last-child) {
    margin-bottom: rem-calc(24);
  }

  &__inputs {
    & > :not(:last-child) {
      margin-bottom: rem-calc(24);
    }
  }

  &__confirm {
    text-align: center;
    color: var(--text-color-primary);
    & > :not(:last-child) {
      margin-bottom: rem-calc(24);
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column-reverse;

    & > :last-child {
      margin-bottom: rem-calc(16);
    }

    @include large {
      flex-direction: row;
      & > :last-child {
        margin-left: rem-calc(16);
        margin-bottom: 0;
      }
    }
  }
}
</style>
