<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="closeTitle">Stäng</title>
    <rect
      x="6.51465"
      y="22.071"
      width="22"
      height="2"
      rx="1"
      transform="rotate(-45 6.51465 22.071)"
      fill="#514F4F"
    />
    <rect
      x="7.92871"
      y="6.51471"
      width="22"
      height="2"
      rx="1"
      transform="rotate(45 7.92871 6.51471)"
      fill="#514F4F"
    />
  </svg>
</template>
