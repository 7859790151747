<template>
  <button :class="classList" :disabled="disabled">
    <StIcon name="st-delete" />
  </button>
</template>
<script>
import { StIcon } from "skanetrafiken";
export default {
  name: "st-delete",
  components: {
    StIcon,
  },
  props: {
    disabled: Boolean,
  },
  computed: {
    classList() {
      return ["st-delete", this.disabled && "st-delete--disabled"];
    },
  },
};
</script>
<style lang="scss" scoped>
.st-delete {
  background: transparent;
  border: none;
  cursor: pointer;

  &--disabled {
    cursor: auto;
    opacity: 0.3;
  }
}
</style>
