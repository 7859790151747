import { required, maxLength } from "@vuelidate/validators";

function postalCodeFormat(value) {
  let regex = /^\s*[0-9]{3}[\s]?[0-9]{2}\s*$/;
  return regex.test(value);
}

export default {
  street: {
    required,
    maxLength: maxLength(50),
  },
  postalCode: {
    required,
    postalCodeFormat,
  },
  co: {
    maxLength: maxLength(50),
  },
  city: {
    required,
    maxLength: maxLength(25),
  },
};
