<template>
  <div class="st-bo-account-tab-bar">
    <StTabs :tabs="getTabs()">
      <template #invoicedetails>
        <AccountBillingInformation :is-disabled="isDisabled" />
      </template>
      <template #admins>
        <AccountAdmins />
      </template>
      <template #travellers>
        <AccountTravellers />
      </template>
    </StTabs>
  </div>
</template>
<script>
import AccountBillingInformation from "@/components/AccountBillingInformation.vue";
import AccountAdmins from "@/components/AccountAdmins.vue";
import AccountTravellers from "@/components/AccountTravellers.vue";
import { StTabs } from "skanetrafiken";

export default {
  name: "st-bo-account-tab-bar",
  props: {
    isDisabled: Boolean,
    onlyBilling: Boolean,
  },
  components: {
    StTabs,
    AccountBillingInformation,
    AccountAdmins,
    AccountTravellers,
  },
  setup(props) {
    const getTabs = () => {
      const tabs = [
        {
          type: "invoicedetails",
          name: "Faktureringsuppgifter",
        },
        {
          type: "admins",
          name: "Administratörer",
        },
        {
          type: "travellers",
          name: "Resenärer",
        },
      ];

      if (props.onlyBilling) {
        return tabs.filter((tab) => tab.type === "invoicedetails");
      }

      return tabs;
    };

    return {
      AccountBillingInformation,
      AccountAdmins,
      AccountTravellers,
      getTabs,
    };
  },
};
</script>
