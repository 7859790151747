<template>
  <div class="st-domain-view">
    <StHeadline>Domän</StHeadline>
    <div class="st-domain-view__action-bar">
      <div class="st-domain-view__action-bar__search">
        <QuickSearch
          v-model="domainQuery"
          @search="searchWhitelistings"
          label-name="Sök på domän, organisationsnummer eller organisationsnamn"
          show-all
        />
      </div>
      <StButton
        primary
        @click="openAddModal(), (addModalOpen = true)"
        v-if="user.state.isAdmin"
        v-slot="props"
      >
        <StIcon
          name="st-plus-small"
          :icon-props="{ color: props.isHoveredOn ? '#3e3b39' : 'white' }"
        />
        <span class="st-domain-view__action-bar__add-text">
          Lägg till vitlistning
        </span>
      </StButton>
    </div>
    <div v-if="isLoading.search">
      <StSpinner loading-message="Söker efter vitlistade domäner..." />
    </div>
    <span v-else-if="searchError" class="st-domain-view__error-text">
      {{ searchError }}
    </span>
    <EmptyList
      v-else-if="emptySearch"
      description="Vi hittade inga vitlistningar som matchade dina sökkriterier. Testa att söka med en annan sökterm."
    />
    <StTable
      v-else-if="tableContent.length"
      :table-data="tableContent"
      :headers="tableHeaders"
      @sort="handleSort"
    />
  </div>
  <DomainAddModal
    ref="addModal"
    :is-open="addModalOpen"
    :error-message="errorMessage"
    :is-loading="isLoading.whitelisting"
    @on-back="errorMessage = ''"
    @create="whitelistDomain"
    @close="handleAddModalClose"
  />
  <DomainConfirmationModal
    ref="confirmationModal"
    :title="confirmationInfo.title"
    :domain="confirmationInfo.domain"
    :organization-name="confirmationInfo.organizationName"
    :organization-number="confirmationInfo.organizationNumber"
    @close="closeConfirmationModal"
  />
</template>
<script setup>
import {
  StHeadline,
  StButton,
  StIcon,
  StTable,
  StSpinner,
} from "skanetrafiken";
import { ref, computed } from "vue";
import { useModal } from "@/composables/useModal";
import QuickSearch from "@/components/QuickSearch.vue";
import DomainAddModal from "@/components/modals/DomainAddModal.vue";
import DomainConfirmationModal from "@/components/modals/DomainConfirmationModal.vue";
import Services from "@/services";
import { useDomainTable } from "@/composables/tables/useDomainTable";
import { useUser } from "@/composables/useUser";
import { useDomain } from "@/composables/useDomain";
import { GENERIC_ERROR_MSG } from "@/constants";
import EmptyList from "@/components/common/EmptyList.vue";

const domainQuery = ref("");

const domain = useDomain();
const user = useUser();
const table = useDomainTable();
const tableHeaders = table.getTableHeaders();

const confirmationModal = ref(null);
const addModal = ref(null);

const addModalOpen = ref(false);

const errorMessage = ref("");
const searchError = ref("");
const emptySearch = ref(false);

const isLoading = ref({
  whitelisting: false,
  search: false,
  organization: false,
});

const confirmationInfo = ref({
  title: "",
  domain: "",
  organizationName: "",
  organizationNumber: "",
});

const [openConfirmationModal, closeConfirmationModal] =
  useModal(confirmationModal);
const [openAddModal, closeAddModal] = useModal(addModal);

const tableContent = computed(() => {
  return table.getTableContent(domain.whitelistings.value);
});

const handleSort = (sortBy, order) => {
  table.sortTable(domain.whitelistings.value, sortBy, order);
};

const whitelistDomain = async (inputs) => {
  isLoading.value.whitelisting = true;
  errorMessage.value = "";
  try {
    const payload = {
      createdBy: user.state.email,
      organizationNumber: inputs.organizationNumber.replace(/-/g, ""),
      companyName: inputs.organizationName,
      domain: inputs.domain,
    };
    await Services.Domain.POST.WHITELIST_DOMAIN(payload);
    domain.clearWhitelistings();
    handleAddModalClose();
    confirmationInfo.value = {
      title: "Domänen är vitlistad",
      domain: inputs.domain,
      organizationName: inputs.organizationName,
      organizationNumber: inputs.organizationNumber,
    };
    openConfirmationModal();
  } catch (err) {
    if (err.response.status === 406) {
      errorMessage.value =
        "Denna domän är redan vitlistad för detta organisationsnummer.";
    } else {
      errorMessage.value = GENERIC_ERROR_MSG;
    }
  } finally {
    isLoading.value.whitelisting = false;
  }
};

const searchWhitelistings = async (searchAll) => {
  isLoading.value.search = true;
  searchError.value = "";
  emptySearch.value = false;
  const payload = {
    query: domainQuery.value,
    fetchAll: searchAll,
  };
  try {
    const res = await Services.Domain.POST.SEARCH_WHITELISTINGS(payload);
    domain.setWhitelistings(res);
    if (!res.length) {
      emptySearch.value = true;
    }
  } catch (err) {
    searchError.value = `Hoppsan, just nu verkar det som att något inte stämmer. Vänligen testa senare igen! ${err}`;
  } finally {
    isLoading.value.search = false;
  }
};

const handleAddModalClose = () => {
  closeAddModal();
  errorMessage.value = "";
  addModalOpen.value = false;
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/functions.scss";
@import "~skanetrafiken/dist/scss/breakpoints.scss";
.st-domain-view {
  padding: rem-calc(20);
  display: flex;
  flex-direction: column;
  text-align: start;

  &__action-bar {
    display: flex;
    flex-direction: column-reverse;
    padding: rem-calc(24) 0;
    margin-bottom: rem-calc(24);

    & > :last-child {
      max-height: rem-calc(48);
      margin-bottom: rem-calc(16);
    }

    @include large {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      & > :last-child {
        margin-bottom: 0;
      }
    }

    &__search {
      max-width: rem-calc(600);

      @include large {
        min-width: rem-calc(800);
      }
    }

    &__add-text {
      margin-left: rem-calc(8);
    }
  }

  &__error-text {
    padding: rem-calc(16);
    text-align: center;
  }
}
</style>
