<template>
  <StModal size="medium" :on-close="() => emit(CLOSE)">
    <div class="st-bo-remove-modal">
      <StHeadline :level="2">{{ title }}</StHeadline>
      <p class="st-bo-remove-modal__paragraph">
        {{ text }}
      </p>
      <div class="st-bo-remove-modal__buttons">
        <st-button full-width @click="$emit(CLOSE)" tabindex="0">
          Avbryt
        </st-button>
        <st-button
          primary
          full-width
          @click="$emit('remove')"
          tabindex="0"
          :is-loading="isLoading"
        >
          Ta bort
        </st-button>
      </div>
    </div>
  </StModal>
</template>

<script>
import { StModal, StButton, StHeadline } from "skanetrafiken";
import { CLOSE } from "@/constants";
export default {
  name: "st-bo-remove-modal",
  components: {
    StModal,
    StButton,
    StHeadline,
  },
  props: {
    title: String,
    text: String,
    isLoading: Boolean,
  },
  setup() {
    return { CLOSE };
  },
};
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-remove-modal {
  text-align: center;
  font-family: $source-sans-pro;
  &__paragraph {
    margin: rem-calc(30) 0 rem-calc(30) 0;
  }

  &__buttons {
    display: flex;

    & > :last-child {
      margin-left: rem-calc(10);
    }
  }
}
</style>
