import { reactive } from "vue";
import jwt_decode from "jwt-decode";

const state = reactive({
  isAdmin: false,
  loading: false,
  email: "",
});

export const useUser = () => {
  const getRole = (accessToken) => {
    const decodedToken = jwt_decode(accessToken);
    if (decodedToken.roles) {
      state.email = decodedToken.unique_name;
      state.isAdmin = decodedToken.roles.includes("backoffice-admin");
      return true;
    }
    return false;
  };

  const setLoading = (value) => {
    state.loading = value;
  };

  return { state, getRole, setLoading };
};
