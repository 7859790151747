<template>
  <div class="st-bo-search">
    <label for="term-select" class="st-bo-search__search__label">
      Sökkategori
    </label>
    <st-select
      id="term-select"
      class="st-bo-search__select"
      v-model="search.state.selectedCategory"
      :items="options"
      @change="() => search.clearInputError()"
      :hasEmpty="false"
    />

    <div class="st-bo-search__search">
      <label for="search-input" class="st-bo-search__search__label">
        Sökord
      </label>
      <st-input
        id="search-input"
        v-model="search.state.searchQuery"
        tabindex="0"
        type="text"
        @keyup.enter="search.isValidSearch() && submit()"
      />
      <span class="st-bo-search__search__error">
        {{ error }}
      </span>
    </div>
    <st-button
      class="st-bo-search__btn"
      @click="submit()"
      @keyup.enter="submit()"
      primary
      :disabled="!search.isValidSearch()"
      tabindex="0"
      v-slot="props"
    >
      <StIcon
        name="st-search"
        :icon-props="{
          color: props.isHoveredOn ? '#3e3b39' : 'white',
        }"
      />
      Sök
    </st-button>
  </div>
</template>
<script setup>
import { StInput, StButton, StSelect, StIcon } from "skanetrafiken";
import { useSearch } from "@/composables/useSearch";
import { computed, onActivated, onMounted, ref } from "vue";

const props = defineProps({ searchType: String });

const search = useSearch();

const options = ref([]);

onActivated(() => {
  setupSearch();
});

onMounted(() => {
  setupSearch();
});

const setupSearch = () => {
  options.value = search.getSearchOptions(props.searchType);
  search.setDefaultSearchOption(options.value[0].id);
};

const submit = async () => {
  await search.searchData(props.searchType);
};

const error = computed(() => search.state.inputError);
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";

.st-bo-search {
  display: flex;
  flex-direction: column;
  padding-top: rem-calc(8);
  padding-bottom: rem-calc(24);
  text-align: flex-start;

  & > :not(:last-child) {
    margin-bottom: rem-calc(16);
  }

  @include large {
    flex-direction: row;
    vertical-align: top;

    &__select {
      width: 20%;
    }

    &__search {
      width: 20%;

      &__label {
        @include sr-only();
      }
    }

    & > :not(:last-child) {
      margin-right: rem-calc(15);
      margin-bottom: 0;
    }
  }

  &__btn {
    max-height: rem-calc(48);

    & > :first-child {
      margin-right: rem-calc(8);
    }
  }
}
</style>
