<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.78881 22.294C4.63851 22.2937 4.48991 22.2622 4.35245 22.2014C4.215 22.1406 4.0917 22.0518 3.99039 21.9408C3.88726 21.8307 3.80856 21.7001 3.75946 21.5574C3.71036 21.4148 3.69196 21.2634 3.70548 21.1131L3.97089 18.1946L16.2321 5.93779L20.0638 9.76846L7.80589 22.0242L4.88739 22.2896C4.85462 22.2926 4.82172 22.2941 4.78881 22.294V22.294ZM20.8286 9.00254L16.998 5.17187L19.2957 2.87412C19.3963 2.7734 19.5158 2.69349 19.6473 2.63898C19.7788 2.58446 19.9198 2.5564 20.0622 2.5564C20.2046 2.5564 20.3455 2.58446 20.477 2.63898C20.6086 2.69349 20.728 2.7734 20.8286 2.87412L23.1264 5.17187C23.2271 5.27249 23.307 5.39197 23.3615 5.52348C23.4161 5.65499 23.4441 5.79597 23.4441 5.93833C23.4441 6.0807 23.4161 6.22167 23.3615 6.35318C23.307 6.4847 23.2271 6.60418 23.1264 6.70479L20.8297 9.00146L20.8286 9.00254V9.00254Z"
      fill="black"
    />
  </svg>
</template>
