import { required, helpers, maxLength } from "@vuelidate/validators";
const { withAsync } = helpers;
import Services from "@/services";

const organizationNumberValidation = /^\s*[0-9]{6}[-]?[0-9]{4}\s*$/;
const domainValidation = /^@[a-z0-9-.]+\.[a-z]{2,}$/;

export default {
  domain: {
    required,
    format: helpers.regex(domainValidation),
    maxLength: maxLength(50),
  },
  organizationNumber: {
    required,
    format: helpers.regex(organizationNumberValidation),
    existingOrganization: withAsync(async (value, model) => {
      if (!organizationNumberValidation.test(value)) return true;

      model.loadingOrganization = true;

      const searchQuery = value.replace(/-/g, "");

      const org = await Services.Account.GET.ORGANIZATION(searchQuery);
      model.loadingOrganization = false;
      if (org.length) {
        model.organizationName = org[0].organizationName;
        return true;
      }
      return false;
    }),
  },
};
