import { reactive } from "vue";
import axios from "@/axios";
import { useTable } from "@/composables/useTable";
import { ACCOUNT_TRAVELLER, ADMIN } from "@/constants";
import config from "~/config";
import Services from "@/services";

const state = reactive({
  account: {
    accountId: 0,
    organizationNumber: "",
    organizationName: "",
    accountName: "",
    ugNumber: "",
    peppolId: "",
    isCreditWorthy: false,
    blocked: false,
    reference: "",
    invoiceEmail: "",
    distributionType: 1,
    authorizedAddresses: [],
    invoiceInfo: {
      street: "",
      co: "",
      postalCode: "",
      city: "",
      sveaPublicKey: "",
      sveaAddressSelector: "",
    },
    pendingInvoiceInfo: undefined,
    travellers: [],
    invitedTravellers: 0,
    admins: [],
  },
  accountApplications: [],
  accountVerifications: [],
});

export const useAccount = () => {
  const table = useTable();

  const getAccountDetails = async (accountId) => {
    try {
      const { data } = await axios.get(
        `${config.BASE_URL}/GetDetailedAccountInfo?accountId=${accountId}`
      );
      Object.assign(state.account, data.accountsDetails);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getAccountAdmins = async () => {
    try {
      const { data } = await axios.get(
        `${config.BASE_URL}/GetUsersByAccount?accountId=${state.account.accountId}`
      );
      state.account.admins = data.admins.map((admin) => {
        return {
          tableInfo: { items: table.getTableInfo(ADMIN, admin) },
          ...admin,
        };
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getAccountTravellers = async () => {
    try {
      const { data } = await axios.get(
        `${config.BASE_URL}/GetTravellersByAccount?accountId=${state.account.accountId}`
      );

      state.account.travellers = data.travellers.map((traveller) => {
        return {
          tableInfo: {
            items: table.getTableInfo(ACCOUNT_TRAVELLER, traveller),
          },
          ...traveller,
        };
      });
      state.account.invitedTravellers = data.invitedToBuyInApp;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const setSelectedAccount = (accountId) => {
    state.account = { ...state.account, accountId };
  };

  const updateAccountBlock = async (blockOnOrgNumber) => {
    const blocked = !state.account.blocked;
    const payload = {
      accountId: state.account.accountId,
      organizationNumber: state.account.organizationNumber,
      blocked,
      allAccounts: blockOnOrgNumber,
    };
    try {
      await axios.put(`${config.BASE_URL}/UpdateBlocked`, payload);
      state.account = { ...state.account, blocked };
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getAccountOrgName = async () => {
    try {
      const { data } = await axios.get(
        `${config.BASE_URL}/UpdateCompanyName?organizationNumber=${state.account.organizationNumber}`
      );
      state.account = {
        ...state.account,
        organizationName: data,
      };
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const updatePurchaseStatus = async () => {
    const purchaseStatus = !state.account.isCreditWorthy;
    const payload = {
      accountId: state.account.accountId,
      isCreditWorthy: purchaseStatus,
    };
    try {
      const { data } = await axios.put(
        `${config.BASE_URL}/UpdateIsCreditWorthy`,
        payload
      );
      state.account = { ...state.account, isCreditWorthy: data.isCreditWorthy };
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const updateInvoiceInfo = async (billingInformation) => {
    const payload = {
      account_Id: parseInt(state.account.accountId),
      invoiceEmail: billingInformation.invoiceEmail,
      distributionType: billingInformation.distributionType,
      reference: billingInformation.reference,
      peppolId: billingInformation.peppolId,
      address: {
        ...state.account.invoiceInfo,
      },
    };
    try {
      await axios.put(`${config.BASE_URL}/UpdateInvoiceInfo`, payload);
      state.account = {
        ...state.account,
        invoiceEmail: billingInformation.invoiceEmail,
        reference: billingInformation.reference,
        peppolId: billingInformation.peppolId,
        distributionType: billingInformation.distributionType,
      };
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const updateAddress = async (address, isNewAddress) => {
    const payload = {
      account_Id: parseInt(state.account.accountId),
      invoiceEmail: state.account.invoiceEmail,
      distributionType: state.account.distributionType,
      reference: state.account.reference,
      peppolId: state.account.peppolId,
      address: {
        ...address,
      },
    };
    try {
      const { data } = await axios.put(
        `${config.BASE_URL}/UpdateInvoiceInfo`,
        payload
      );
      state.account = {
        ...state.account,
        invoiceInfo: isNewAddress ? data.account.invoiceInformation : address,
        pendingInvoiceInfo: isNewAddress
          ? data.pendingInvoiceInformation
          : undefined,
      };
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const updateAccountDetails = async (accountName, ugNumber) => {
    const payload = {
      accountId: state.account.accountId,
      displayName: accountName,
      sveaAccountTypeNumber: ugNumber,
    };
    try {
      const { data } = await axios.put(
        `${config.BASE_URL}/UpdateAccountInfo`,
        payload
      );
      state.account = {
        ...state.account,
        accountName: data.displayName,
        ugNumber: data.sveaAccountTypeNumber,
      };
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const updateSveaKey = async (newSveaPublicKey) => {
    const payload = {
      accountId: state.account.accountId,
      newKey: newSveaPublicKey,
    };
    try {
      await axios.put(`${config.BASE_URL}/UpdateSveaPublicKey`, payload);
      state.account.invoiceInfo.sveaPublicKey = newSveaPublicKey;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const isSelectedAccount = (accountId) => {
    return parseInt(state.account.accountId) === accountId;
  };

  const removeAccountTraveller = (travellerId) => {
    state.account.travellers = state.account.travellers.filter(
      (traveller) => traveller.travellerId !== travellerId
    );
  };

  const addAccountTraveller = (accountTraveller) => {
    state.account.travellers.push({
      tableInfo: {
        items: table.getTableInfo(ACCOUNT_TRAVELLER, accountTraveller),
      },
      ...accountTraveller,
    });
  };

  const updateAccountAdmin = (admin) => {
    state.account.admins = state.account.admins.map((existingAdmin) =>
      existingAdmin.userId === admin.userId ? admin : existingAdmin
    );
  };

  const removeAccountAdmin = (userId) => {
    state.account.admins = state.account.admins.filter(
      (admin) => admin.userId !== userId
    );
  };

  const fetchAccountApplications = async () => {
    try {
      state.accountApplications =
        await Services.Account.GET.NOT_APPROVED_ACCOUNTS();
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const fetchAccountVerifications = async (searchText) => {
    try {
      const notVerifiedAccounts =
        await Services.Account.GET.NOT_VERIFIED_ACCOUNTS(searchText);
      state.accountVerifications = notVerifiedAccounts;
      return notVerifiedAccounts;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getAccountVerificationDetails = async (id) => {
    try {
      const details = await Services.Account.GET.ACCOUNT_VERIFICATION_DETAILS(
        id
      );
      Object.assign(state.account, details, {
        organizationName: details.companyName,
        isCreditWorthy: false,
        blocked: true,
        invoiceInfo: {
          street: details.street,
          co: details.co,
          postalCode: details.postalCode,
          city: details.city,
          sveaPublicKey: details.sveaPublicKey,
        },
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getAccountApplicationDetails = async (id) => {
    try {
      const details = await Services.Account.GET.ACCOUNT_APPLICATION_DETAILS(
        id
      );
      Object.assign(state.account, details, {
        organizationName: details.companyName,
        isCreditWorthy: false,
        blocked: true,
        invoiceInfo: {
          street: details.street,
          co: details.co,
          postalCode: details.postalCode,
          city: details.city,
          sveaPublicKey: details.sveaPublicKey,
        },
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const removeAccountApplication = (idToRemove) => {
    state.accountApplications = state.accountApplications.filter(
      (account) => account.id !== idToRemove
    );
  };

  const removeAccountVerification = (idToRemove) => {
    state.accountVerifications = state.accountVerifications.filter(
      (account) => account.id !== idToRemove
    );
  };

  const updateAccountVerificationId = (oldId, newId) => {
    state.accountVerifications = state.accountVerifications.map(
      (verification) =>
        verification.id === oldId
          ? { ...verification, id: newId }
          : verification
    );
  };

  const resetAccountVerifications = () => {
    state.accountVerifications = [];
  };

  return {
    state,
    getAccountDetails,
    setSelectedAccount,
    updateAccountBlock,
    updatePurchaseStatus,
    getAccountAdmins,
    getAccountTravellers,
    updateInvoiceInfo,
    updateAccountDetails,
    addAccountTraveller,
    removeAccountTraveller,
    getAccountOrgName,
    updateSveaKey,
    updateAccountAdmin,
    removeAccountAdmin,
    isSelectedAccount,
    updateAddress,
    fetchAccountApplications,
    fetchAccountVerifications,
    getAccountVerificationDetails,
    getAccountApplicationDetails,
    removeAccountApplication,
    removeAccountVerification,
    resetAccountVerifications,
    updateAccountVerificationId,
  };
};
