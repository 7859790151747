<template>
  <StModal :on-close="() => emit(CLOSE)">
    <div class="st-domain-removal-modal">
      <div class="st-domain-removal-modal__header">
        <StIcon name="st-warning-big" />
        <StHeadline :level="2"> Ta bort vitlistning </StHeadline>
        <p class="st-domain-removal-modal__header__paragraph">
          Är du säker på att du vill ta bort vitlistningen? Kontoansökningar
          från denna domänen kommer i framtiden behövas godkännas manuellt.
        </p>
      </div>
      <ModalDomainDetails
        :domain="whitelisting.domain"
        :organization-number="whitelisting.organizationNumber"
        :organization-name="whitelisting.companyName"
      />
      <div class="st-domain-removal-modal__buttons">
        <StButton full-width @click="emit(CLOSE)"> Avbryt </StButton>
        <StButton
          primary
          full-width
          @click="emit('remove')"
          :is-loading="isLoading"
        >
          Ta bort vitlistning
        </StButton>
      </div>
      <StError v-if="errorMessage" :error-message="errorMessage" />
    </div>
  </StModal>
</template>

<script setup>
import { StModal, StButton, StHeadline, StIcon, StError } from "skanetrafiken";
import { CLOSE } from "@/constants";
import ModalDomainDetails from "../common/ModalDomainDetails.vue";

const emit = defineEmits(["remove", "close"]);

defineProps({
  whitelisting: Object,
  isLoading: Boolean,
  errorMessage: String,
});
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/functions.scss";
@import "~skanetrafiken/dist/scss/breakpoints.scss";
.st-domain-removal-modal {
  font-family: var(--font-primary);

  @include large {
    width: rem-calc(450);
  }

  &__header {
    text-align: center;

    & > :not(:last-child) {
      margin-bottom: rem-calc(24);
    }

    &__paragraph {
      color: var(--text-color-primary);
      line-height: rem-calc(20);
    }
  }

  & > :not(:last-child) {
    margin-bottom: rem-calc(24);
  }

  &__buttons {
    display: flex;
    flex-direction: column-reverse;

    & > :last-child {
      margin-bottom: rem-calc(16);
    }

    @include large {
      flex-direction: row;
      & > :last-child {
        margin-left: rem-calc(16);
        margin-bottom: 0;
      }
    }
  }
}
</style>
