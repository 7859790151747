<template>
  <div class="st-bo-traveller-view">
    <StHeadline>Resenär</StHeadline>
    <search :search-type="TRAVELLER" />
    <div v-if="searchLoading" class="st-bo-traveller-view__loader">
      <st-spinner loading-message="Söker resenärer..." />
    </div>
    <span v-else-if="error" class="st-bo-traveller-view__error-text">
      {{ error }}
    </span>
    <template v-else-if="result.length">
      <StHeadline :level="2">Resenärer</StHeadline>
      <span class="st-bo-traveller-view__count-text">
        Antal resultat: {{ result.length }}
      </span>
      <st-table
        :table-data="result"
        :headers="headers"
        @on-item-click="deleteClick"
        read-only
      />
    </template>
    <EmptyList
      v-else-if="searchEmpty"
      description="Vi hittade inga resenärer som matchade dina sökkriterier. Testa att söka med en annan sökterm eller kategori."
    />
    <RemoveModal
      ref="removeModal"
      title="Ta bort resenär"
      :text="`Är du säker på att du vill ta bort ${traveller.state.traveller.name}?`"
      :is-loading="isLoading.removeTraveller"
      @remove="handleRemove"
      @close="closeRemoveModal"
    />
    <ConfirmationModal
      ref="confirmationModal"
      :title="confirmationModalTitle"
      :status="!removeError"
      :error="removeError"
      @close="closeConfirmationModal"
    />
  </div>
</template>

<script>
import { computed, onBeforeMount, reactive, ref, onBeforeUnmount } from "vue";
import Search from "@/components/Search.vue";
import { useSearch } from "@/composables/useSearch";
import { useTable } from "@/composables/useTable";
import { useUser } from "@/composables/useUser";
import { useModal } from "@/composables/useModal";
import { useTraveller } from "@/composables/useTraveller";
import { StTable, StSpinner, StHeadline } from "skanetrafiken";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import RemoveModal from "@/components/modals/RemoveModal.vue";
import EmptyList from "@/components/common/EmptyList.vue";
import { TRAVELLER, GENERIC_ERROR_MSG } from "@/constants";

export default {
  name: "st-bo-traveller-view",
  components: {
    Search,
    StTable,
    StSpinner,
    StHeadline,
    ConfirmationModal,
    RemoveModal,
    EmptyList,
  },
  setup() {
    const search = useSearch();
    const table = useTable();
    const user = useUser();
    const traveller = useTraveller();

    const removeModal = ref(null);
    const confirmationModal = ref(null);

    const [openRemoveModal, closeRemoveModal] = useModal(removeModal);

    const [openConfirmationModal, closeConfirmationModal] =
      useModal(confirmationModal);

    const removeError = ref(undefined);

    const isLoading = reactive({
      removeTraveller: false,
    });

    onBeforeMount(() => {
      search.clearSearch();
    });

    onBeforeUnmount(() => {
      search.clearSearch();
    });

    const handleRemove = async () => {
      try {
        isLoading.removeTraveller = true;
        await traveller.removeTraveller(
          traveller.state.traveller.travellerId,
          TRAVELLER
        );
        isLoading.removeTraveller = false;
        closeRemoveModal();
        openConfirmationModal();
      } catch (err) {
        isLoading.removeTraveller = false;
        removeError.value = err.response.data || err;
        closeRemoveModal();
        openConfirmationModal();
      }
    };

    const deleteClick = (index) => {
      if (user.state.isAdmin) {
        traveller.setSelectedTraveller(search.state.result[index]);
        openRemoveModal();
      }
    };

    return {
      headers: table.getTableHeaders(TRAVELLER),
      result: computed(() =>
        search.state.result.map((account) => account.tableInfo)
      ),
      error: computed(() => search.state.resultError),
      searchLoading: computed(() => search.state.loading),
      deleteClick,
      TRAVELLER,
      handleRemove,
      isLoading: computed(() => isLoading),
      removeModal,
      confirmationModal,
      closeRemoveModal,
      closeConfirmationModal,
      traveller,
      removeError,
      confirmationModalTitle: computed(() =>
        removeError.value
          ? GENERIC_ERROR_MSG
          : `${traveller.state.traveller.name} är nu borttagen.`
      ),
      searchEmpty: computed(() => search.state.emptySearch),
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-traveller-view {
  padding: rem-calc(20);
  display: flex;
  flex-direction: column;
  text-align: start;

  &__loader {
    padding-top: rem-calc(30);
  }

  &__count-text {
    align-self: flex-start;
    color: var(--text-color-secondary);
    font-weight: 700;
    padding: rem-calc(8) 0;
  }
  &__error-text {
    padding: rem-calc(16);
    text-align: center;
  }
}
</style>
