<template>
  <StModal size="medium" :on-close="() => emit(CLOSE)">
    <div class="st-account-denial-modal">
      <div class="st-account-denial-modal__header">
        <StIcon name="st-warning-big" />
        <StHeadline :level="2"> {{ title }} </StHeadline>
        <p class="st-account-denial-modal__header__paragraph">
          {{ description }}
        </p>
      </div>
      <ModalAccountDetails
        :account-name="account.accountName"
        :email="account.email"
        :organization-name="account.companyName"
      />
      <StFormGroup
        v-if="withReason"
        for-id="reason-select"
        label="Anledning"
        required
        :validation="v$.reason"
        :validation-errors="AccountApplicationValidationsErrors.reason"
      >
        <StSelect
          id="reason-select"
          v-model="selectedReason"
          :items="options"
          required
          :invalid="v$ && v$.reason && v$.reason.$error"
          empty-option-text="Välj en anledning"
        />
      </StFormGroup>

      <div class="st-account-denial-modal__buttons">
        <StButton full-width @click="emit(CLOSE)"> Avbryt </StButton>
        <StButton
          primary
          full-width
          @click="handleClick"
          :is-loading="isLoading"
        >
          {{ actionText }}
        </StButton>
      </div>
      <StError v-if="errorMessage" :error-message="errorMessage" />
    </div>
  </StModal>
</template>

<script setup>
import {
  StModal,
  StButton,
  StHeadline,
  StIcon,
  StSelect,
  StFormGroup,
  StError,
} from "skanetrafiken";
import { CLOSE } from "@/constants";
import { ref, watch } from "vue";
import ModalAccountDetails from "../common/ModalAccountDetails.vue";
import { useVuelidate } from "@vuelidate/core";
import AccountApplicationValidations from "@/validators/account-application-validations";
import AccountApplicationValidationsErrors from "@/validators/account-application-validation-errors";

const emit = defineEmits(["on-denial", "close"]);

const props = defineProps({
  isLoading: Boolean,
  withReason: Boolean,
  account: Object,
  errorMessage: String,
  isOpen: Boolean,
  title: {
    type: String,
    default: "Neka konto",
  },
  description: {
    type: String,
    default: "Är du säker på att du vill neka kontot?",
  },
  actionText: {
    type: String,
    default: "Neka konto",
  },
});
const selectedReason = ref(undefined);

const v$ = useVuelidate(AccountApplicationValidations, {
  reason: selectedReason,
});

const options = [
  { id: 1, name: "Fel domän" },
  { id: 2, name: "Privat mejladress" },
  { id: 3, name: "Ej verksamt i skåne" },
  { id: 4, name: "Känd bedragare" },
];

const handleClick = () => {
  if (props.withReason) {
    v$.value.$touch();
    const errors = v$.value.$errors;
    if (errors.length) return;
  }
  emit("on-denial", false, selectedReason.value);
};

watch(
  () => props.isOpen,
  (isOpen) => {
    if (!isOpen) {
      selectedReason.value = undefined;
      v$.value.$reset();
    }
  }
);
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-account-denial-modal {
  font-family: var(--font-primary);
  max-width: rem-calc(600);

  &__header {
    text-align: center;

    & > :not(:last-child) {
      margin-bottom: rem-calc(24);
    }

    &__paragraph {
      color: var(--text-color-primary);
      line-height: rem-calc(20);
    }
  }

  & > :not(:last-child) {
    margin-bottom: rem-calc(24);
  }

  &__buttons {
    display: flex;

    & > :last-child {
      margin-left: rem-calc(16);
    }
  }
}
</style>
