import { required, email, maxLength, requiredIf } from "@vuelidate/validators";

function checkIfEmail(_, billingInformation) {
  return billingInformation.distributionType === 2 ? true : false;
}
function checkIfPeppol(_, billingInformation) {
  return billingInformation.distributionType === 4 ? true : false;
}

function peppolIdValidation(peppolId) {
  if (!peppolId) return true;

  if (peppolId.length < 6 || peppolId.length > 55) {
    return false;
  }

  let regex = /^[0-9]*$/;
  if (!regex.test(peppolId.substring(0, 4))) {
    return false;
  }

  if (peppolId.substring(4, 5) !== ":") {
    return false;
  }

  regex = /^[a-zA-Z0-9]*$/;
  if (!regex.test(peppolId.substring(5, peppolId.length))) {
    return false;
  }

  return true;
}

export default {
  invoiceEmail: {
    required: requiredIf(checkIfEmail),
    emailFormat: email,
    maxLength: maxLength(100),
  },
  peppolId: {
    required: requiredIf(checkIfPeppol),
    peppolIdValidation,
  },
  reference: {
    required,
    maxLength: maxLength(32),
  },
};
