import { createRouter, createWebHistory } from "vue-router";
import AccountView from "../views/AccountView.vue";
import AdminView from "../views/AdminView.vue";
import TravellerView from "../views/TravellerView.vue";
import AccountDetailsView from "../views/AccountDetailsView.vue";
import DomainView from "../views/DomainView.vue";
import { registerGuard } from "./Guard";
import {
  ACCOUNT_CREATED,
  ACCOUNT_APPLICATION,
  ACCOUNT_VERIFICATION,
} from "@/constants";
import config from "~/config";

const routes = [
  {
    path: "/",
    component: AccountView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account-details/:accountId",
    name: "AccountDetails",
    props: (route) => ({
      accountId: route.params.accountId,
      accountType: ACCOUNT_CREATED,
    }),
    component: AccountDetailsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account-verification-details/:accountId",
    name: "AccountVerificationDetails",
    props: (route) => ({
      accountId: route.params.accountId,
      accountType: ACCOUNT_VERIFICATION,
      isLocked: true,
    }),
    component: AccountDetailsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account-application-details/:accountId",
    name: "AccountApplicationDetails",
    props: (route) => ({
      accountId: route.params.accountId,
      accountType: ACCOUNT_APPLICATION,
      isLocked: true,
    }),
    component: AccountDetailsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admins",
    name: "Admins",
    component: AdminView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/travellers",
    name: "Travellers",
    component: TravellerView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/domains",
    name: "Domains",
    component: DomainView,
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      config.APPROVAL_ENABLED ? next() : next("/");
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active-link",
});

registerGuard(router);

export default router;
