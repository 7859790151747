// import api from "@/axios";
// import config from "~/config";

export default {
  GET: {},
  POST: {},
  PUT: {},
  DELETE: {},
  PATCH: {},
};
