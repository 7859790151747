<template>
  <div class="st-bo-account-admins">
    <st-spinner
      v-if="isLoading.fetchingAdmins"
      loading-message="Laddar..."
      class="st-bo-account-admins__spinner"
    />
    <error
      v-else-if="error.text"
      :text="error.text"
      :error-message="error.errMessage"
      :error-code="error.errCode"
    />
    <div v-else class="st-bo-account-admins__content">
      <div class="st-bo-account-admins__content__handle">
        <span>Antal administratörer: {{ admins.length }}</span>
        <st-button
          primary
          @click="openAdminAddModal"
          tabindex="0"
          :disabled="isNotAdmin"
        >
          Bjud in administratör
        </st-button>
      </div>
      <StHeadline :level="4">Administratörer</StHeadline>
      <st-table
        :table-data="admins"
        :headers="headers"
        @on-row-click="rowClick"
        clickable
      />
      <admin-info-modal
        ref="adminModal"
        :account-admin="true"
        @handle-block="openBlockAdminModal"
        @handle-remove="openRemoveModal"
        @close="closeAdminModal"
      />
      <add-admin-modal
        ref="adminAddModal"
        @close="closeAdminAddModal"
        @add="handleAdd"
        :is-loading="isLoading.addAdmin"
      />
      <block-admin-modal
        ref="blockAdminModal"
        :text="blockAdminText"
        :is-blocked="adminBlocked"
        :is-loading="isLoading.block"
        @block="blockAdmin"
        @close="closeBlockAdminModal"
      />
      <remove-modal
        ref="removeModal"
        title="Ta bort administratör"
        :text="`Är du säker på att du vill ta bort ${selectedAdminName} från kontot ${selectedAdmin.selectedAdminAccount.displayName}?`"
        @remove="removeAdmin"
        @close="closeRemoveModal"
        :is-loading="isLoading.removeAdmin"
      />
      <confirmation-modal
        ref="confirmationModal"
        :title="showConfirmationModal.title"
        :status="showConfirmationModal.status"
        :error="showConfirmationModal.error"
        @close="handleConfirmationModal"
      />
    </div>
  </div>
</template>
<script>
import { StTable, StButton, StSpinner, StHeadline } from "skanetrafiken";
import { useAccount } from "@/composables/useAccount";
import { useTable } from "@/composables/useTable";
import { useUser } from "@/composables/useUser";
import { useAdmin } from "@/composables/useAdmin";
import { ref, onMounted, computed, reactive } from "vue";
import AddAdminModal from "@/components/modals/AddAdminModal.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import { ADMIN_ACCOUNTS, ACCOUNT_ADMIN, GENERIC_ERROR_MSG } from "@/constants";
import AdminInfoModal from "@/components/modals/AdminInfoModal.vue";
import BlockAdminModal from "@/components/modals/BlockAdminModal.vue";
import RemoveModal from "@/components/modals/RemoveModal.vue";
import Error from "@/components/common/Error.vue";
import { useError } from "@/composables/useError";
import { useModal } from "@/composables/useModal";

export default {
  name: "st-bo-account-admins",
  components: {
    StButton,
    StTable,
    StSpinner,
    AddAdminModal,
    ConfirmationModal,
    AdminInfoModal,
    BlockAdminModal,
    RemoveModal,
    Error,
    StHeadline,
  },
  setup() {
    const account = useAccount();
    const user = useUser();
    const table = useTable();
    const admin = useAdmin();
    const errorMessages = useError();
    const error = ref({ text: "", errMessage: "", errCode: "" });
    const redeployModal = ref(false);

    const showConfirmationModal = ref({
      title: "",
      status: false,
      error: {},
    });

    const isLoading = reactive({
      fetchingAdmins: false,
      block: false,
      addAdmin: false,
      removeAdmin: false,
    });

    const adminModal = ref(null);
    const adminAddModal = ref(null);
    const confirmationModal = ref(null);
    const blockAdminModal = ref(null);
    const removeModal = ref(null);

    const [openAdminModal, closeAdminModal] = useModal(adminModal);
    const [openAdminAddModal, closeAdminAddModal] = useModal(adminAddModal);
    const [openConfirmationModal, closeConfirmationModal] =
      useModal(confirmationModal);
    const [openBlockAdminModal, closeBlockAdminModal] =
      useModal(blockAdminModal);
    const [openRemoveModal, closeRemoveModal] = useModal(removeModal);

    onMounted(async () => {
      try {
        isLoading.fetchingAdmins = true;
        await account.getAccountAdmins();
        isLoading.fetchingAdmins = false;
      } catch (err) {
        isLoading.fetchingAdmins = false;
        error.value = {
          text: "Det går inte att hämta administratörer för detta konto just nu. Vänligen testa senare igen.",
          errMessage: errorMessages.getErrorMessage(
            err.response.data.code || err.code
          ),
          errCode: err.response.data.code || err.code,
        };
      }
    });

    const handleAdd = async (email) => {
      try {
        isLoading.addAdmin = true;
        await admin.addAdmin(email, account.state.account.accountId);
        isLoading.addAdmin = false;
        closeAdminAddModal();
        showConfirmation("Administratör inbjuden.");
      } catch (err) {
        isLoading.addAdmin = false;
        closeAdminAddModal();
        showConfirmation(GENERIC_ERROR_MSG, err.response.data || err);
      }
    };

    const blockAdmin = async () => {
      try {
        isLoading.block = true;
        await admin.blockAdmin(ADMIN_ACCOUNTS);
        isLoading.block = false;
        closeBlockAdminModal();
        redeployModal.value = true;
        const title = `${admin.state.admin.givenName} ${
          admin.state.admin.surname
        } är nu 
            ${admin.state.admin.blocked ? "spärrad" : "aktiverad"}.`;
        showConfirmation(title);
      } catch (err) {
        isLoading.block = false;
        redeployModal.value = true;
        closeBlockAdminModal();
        showConfirmation(GENERIC_ERROR_MSG, err.response.data || err);
      }
    };

    const removeAdmin = async () => {
      try {
        isLoading.removeAdmin = true;
        await admin.removeAdminFromAccount(
          admin.state.selectedAdminAccount.accountId,
          admin.state.admin.userId,
          ADMIN_ACCOUNTS
        );
        isLoading.removeAdmin = false;
        closeRemoveModal();
        redeployModal.value = true;
        const title = `${admin.state.admin.givenName} ${admin.state.admin.surname} är nu borttagen från ${admin.state.selectedAdminAccount.displayName}.`;
        showConfirmation(title);
      } catch (err) {
        isLoading.removeAdmin = false;
        closeRemoveModal();
        redeployModal.value = true;
        showConfirmation(GENERIC_ERROR_MSG, err.response.data || err);
      }
    };

    const handleConfirmationModal = () => {
      closeConfirmationModal();
      if (redeployModal.value) {
        openAdminModal();
        redeployModal.value = false;
      }
    };

    const rowClick = async (_, index) => {
      admin.setSelectedAdmin(account.state.account.admins[index]);
      try {
        await admin.getAdminDetails();
        openAdminModal();
      } catch (err) {
        showConfirmation(
          "Vald admin kunde inte hämtas just nu. Vänligen försök igen senare.",
          err.response.data || err
        );
      }
    };

    const showConfirmation = (title, error) => {
      showConfirmationModal.value = {
        title,
        status: !error,
        error: error ? error : {},
      };
      openConfirmationModal();
    };

    return {
      headers: table.getTableHeaders(ACCOUNT_ADMIN),
      handleAdd,
      admins: computed(() =>
        account.state.account.admins.map((admin) => admin.tableInfo)
      ),
      error,
      showConfirmationModal,
      isNotAdmin: computed(() => !user.state.isAdmin),
      adminBlocked: computed(() => admin.state.admin.blocked),
      rowClick,
      blockAdmin,
      removeAdmin,
      handleConfirmationModal,
      isLoading: computed(() => isLoading),
      adminAddModal,
      openAdminAddModal,
      closeAdminAddModal,
      confirmationModal,
      openConfirmationModal,
      closeConfirmationModal,
      adminModal,
      openAdminModal,
      closeAdminModal,
      blockAdminModal,
      openBlockAdminModal,
      closeBlockAdminModal,
      removeModal,
      openRemoveModal,
      closeRemoveModal,
      selectedAdmin: computed(() => admin.state),
      selectedAdminName: computed(
        () => `${admin.state.admin.givenName} ${admin.state.admin.surname}`
      ),
      blockAdminText: admin.handleAdminBlockText,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";

.st-bo-account-admins {
  display: flex;
  flex-direction: column;
  margin-top: rem-calc(16);

  &__content {
    display: flex;
    flex-direction: column;
    text-align: start;

    &__handle {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-bottom: rem-calc(22);
      font-size: rem-calc(18);

      & > :first-child {
        margin-right: rem-calc(80);
      }
    }
  }

  &__spinner {
    margin-top: rem-calc(16);
  }

  &__error-text {
    margin-top: rem-calc(16);
    text-align: start;
  }
}
</style>
