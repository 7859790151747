<template>
  <div class="st-bo-admin-view">
    <StHeadline>Administratör</StHeadline>
    <search :search-type="ADMIN" />
    <div v-if="searchLoading" class="st-bo-admin-view__loader">
      <st-spinner loading-message="Söker administratörer..." />
    </div>
    <span v-else-if="error" class="st-bo-admin-view__error-text">
      {{ error }}
    </span>
    <template v-else-if="result.length">
      <StHeadline :level="2">Administratörer</StHeadline>
      <span class="st-bo-admin-view__count-text">
        Antal resultat: {{ result.length }}
      </span>
      <st-table
        :table-data="result"
        :headers="headers"
        @on-row-click="rowClick"
        clickable
      />
    </template>
    <EmptyList
      v-else-if="searchEmpty"
      description="Vi hittade inga administratörer som matchade dina sökkriterier. Testa att söka med en annan sökterm eller kategori."
    />
    <admin-info-modal
      ref="adminModal"
      @handle-block="openBlockAdminModal"
      @handle-remove="openRemoveModal"
      @close="closeAdminModal"
    />
    <block-admin-modal
      ref="blockAdminModal"
      :text="blockAdminText"
      :is-blocked="adminBlocked"
      :is-loading="isLoading.block"
      @block="blockAdmin"
      @close="closeBlockAdminModal"
    />
    <remove-modal
      ref="removeModal"
      title="Ta bort administratör"
      :text="`Är du säker på att du vill ta bort ${selectedAdminName} från kontot ${selectedAdmin.selectedAdminAccount.displayName}?`"
      @remove="removeAdmin"
      @close="closeRemoveModal"
      :is-loading="isLoading.removeAdmin"
    />
    <confirmation-modal
      ref="confirmationModal"
      :title="showConfirmationModal.title"
      :status="showConfirmationModal.status"
      :error="showConfirmationModal.error"
      @close="handleConfirmationModal"
    />
  </div>
</template>

<script>
import { computed, onBeforeMount, ref, reactive, onBeforeUnmount } from "vue";
import Search from "@/components/Search.vue";
import { useSearch } from "@/composables/useSearch";
import { StTable, StSpinner, StHeadline } from "skanetrafiken";
import { ADMIN, GENERIC_ERROR_MSG } from "@/constants";
import { useAdmin } from "@/composables/useAdmin";
import { useTable } from "@/composables/useTable";
import AdminInfoModal from "@/components/modals/AdminInfoModal.vue";
import BlockAdminModal from "@/components/modals/BlockAdminModal.vue";
import RemoveModal from "@/components/modals/RemoveModal.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import EmptyList from "@/components/common/EmptyList.vue";
import { useModal } from "@/composables/useModal";

export default {
  name: "st-bo-admin-view",
  components: {
    Search,
    StTable,
    StSpinner,
    StHeadline,
    AdminInfoModal,
    BlockAdminModal,
    ConfirmationModal,
    RemoveModal,
    EmptyList,
  },
  setup() {
    const search = useSearch();
    const admin = useAdmin();
    const table = useTable();

    const redeployModal = ref(false);
    const showConfirmationModal = ref({
      title: "",
      status: false,
      error: {},
    });

    const isLoading = reactive({
      block: false,
      removeAdmin: false,
    });

    const adminModal = ref(null);
    const confirmationModal = ref(null);
    const blockAdminModal = ref(null);
    const removeModal = ref(null);

    const [openAdminModal, closeAdminModal] = useModal(adminModal);
    const [openConfirmationModal, closeConfirmationModal] =
      useModal(confirmationModal);
    const [openBlockAdminModal, closeBlockAdminModal] =
      useModal(blockAdminModal);
    const [openRemoveModal, closeRemoveModal] = useModal(removeModal);

    onBeforeMount(() => {
      search.clearSearch();
    });

    onBeforeUnmount(() => {
      search.clearSearch();
    });

    const handleConfirmationModal = () => {
      closeConfirmationModal();
      if (redeployModal.value) {
        openAdminModal();
        redeployModal.value = false;
      }
    };

    const blockAdmin = async () => {
      try {
        isLoading.block = true;
        await admin.blockAdmin(ADMIN);
        closeBlockAdminModal();
        redeployModal.value = true;
        isLoading.block = false;
        const title = `${admin.state.admin.givenName} ${
          admin.state.admin.surname
        } är nu 
            ${admin.state.admin.blocked ? "spärrad" : "aktiverad"}.`;
        showConfirmation(title);
      } catch (err) {
        closeBlockAdminModal();
        redeployModal.value = true;
        isLoading.block = false;
        showConfirmation(GENERIC_ERROR_MSG, err.response.data || err);
      }
    };

    const rowClick = async (_, index) => {
      admin.setSelectedAdmin(search.state.result[index]);
      try {
        await admin.getAdminDetails();
        openAdminModal();
      } catch (err) {
        showConfirmation(
          "Vald admin kunde inte hämtas just nu. Vänligen försök igen senare.",
          err.response.data || err
        );
      }
    };

    const removeAdmin = async () => {
      try {
        isLoading.removeAdmin = true;
        await admin.removeAdminFromAccount(
          admin.state.selectedAdminAccount.accountId,
          admin.state.admin.userId,
          ADMIN
        );
        isLoading.removeAdmin = false;
        closeRemoveModal();
        redeployModal.value = true;
        const title = `${admin.state.admin.givenName} ${admin.state.admin.surname} är nu borttagen från ${admin.state.selectedAdminAccount.displayName}.`;
        showConfirmation(title);
      } catch (err) {
        isLoading.removeAdmin = false;
        closeRemoveModal();
        redeployModal.value = true;
        showConfirmation(GENERIC_ERROR_MSG, err.response.data || err);
      }
    };

    const showConfirmation = (title, error) => {
      showConfirmationModal.value = {
        title,
        status: !error,
        error: error ? error : {},
      };
      openConfirmationModal();
    };

    return {
      headers: table.getTableHeaders(ADMIN),
      result: computed(() =>
        search.state.result.map((admin) => admin.tableInfo)
      ),
      error: computed(() => search.state.resultError),
      searchLoading: computed(() => search.state.loading),
      rowClick,
      ADMIN,
      blockAdmin,
      showConfirmationModal,
      handleConfirmationModal,
      removeAdmin,
      adminBlocked: computed(() => admin.state.admin.blocked),
      isLoading: computed(() => isLoading),
      confirmationModal,
      openConfirmationModal,
      closeConfirmationModal,
      adminModal,
      openAdminModal,
      closeAdminModal,
      blockAdminModal,
      openBlockAdminModal,
      closeBlockAdminModal,
      removeModal,
      openRemoveModal,
      closeRemoveModal,
      selectedAdmin: computed(() => admin.state),
      selectedAdminName: computed(
        () => `${admin.state.admin.givenName} ${admin.state.admin.surname}`
      ),
      blockAdminText: admin.handleAdminBlockText,
      searchEmpty: computed(() => search.state.emptySearch),
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-admin-view {
  padding: rem-calc(20);
  display: flex;
  flex-direction: column;
  text-align: start;

  &__loader {
    padding-top: rem-calc(30);
  }

  &__count-text {
    align-self: flex-start;
    color: var(--text-color-secondary);
    font-weight: 700;
    padding: rem-calc(8) 0;
  }

  &__error-text {
    padding: rem-calc(16);
    text-align: center;
  }
}
</style>
