import { reactive } from "vue";
import axios from "@/axios";
import { useTable } from "@/composables/useTable";
import config from "~/config";

const state = reactive({
  selectedCategory: "",
  searchQuery: "",
  result: [],
  resultError: undefined,
  inputError: undefined,
  loading: false,
  emptySearch: false,
});

export const useSearch = () => {
  const table = useTable();

  const searchData = async (type) => {
    state.loading = true;
    state.resultError = undefined;
    try {
      const { data } = await axios.get(
        `${config.BASE_URL}/${getSearchPath(type)}?searchType=${
          state.selectedCategory
        }&searchValue=${state.searchQuery}`
      );

      state.result = Object.values(data)[0].map((resultObj) => {
        return {
          tableInfo: { items: table.getTableInfo(type, resultObj) },
          ...resultObj,
        };
      });
      if (!state.result.length) {
        state.emptySearch = true;
      }
      state.loading = false;
    } catch (error) {
      state.loading = false;
      state.result = [];
      setSearchError(true, error);
    }
  };

  const clearSearch = () => {
    state.result = [];
    state.searchQuery = "";
    state.inputError = "";
    state.resultError = "";
    state.emptySearch = false;
  };

  const isValidSearch = () => {
    //Checking for only numbers
    const reg = /^\d+$/;

    if (state.searchQuery.length) {
      if (state.selectedCategory === "accountId") {
        if (!reg.test(state.searchQuery)) {
          state.inputError = " Kundnummer kan endast innehålla siffror";
          return false;
        } else if (state.searchQuery.length > 9) {
          state.inputError = " Kundnummer kan max innehålla 9 siffror";
          return false;
        }
      }
      clearInputError();
      return true;
    }
    clearInputError();
    return false;
  };

  const updateSearchResult = async (searchType) => {
    await searchData(searchType);
  };

  const updateAdminSearchResult = (admin, updateType) => {
    state.result = state.result.map((row) =>
      getUpdateConditional(updateType, row, admin) ? admin : row
    );
  };

  const updateTravellerSearchResult = (travellerId) => {
    state.result = state.result.filter(
      (row) => row.travellerId !== travellerId
    );
  };

  const getUpdateConditional = (updateType, row, admin) => {
    const conditions = {
      adminInfo:
        row.userId === admin.userId &&
        row.phoneNumber === admin.staticPhoneNumber &&
        row.email === admin.staticEmail,
      blocked: row.userId === admin.userId,
      accountCount:
        row.userId === admin.userId &&
        row.phoneNumber === admin.staticPhoneNumber &&
        row.email === admin.staticEmail,
    };
    return conditions[updateType];
  };

  const getSearchOptions = (type) => {
    const options = {
      account: [
        { name: "Kundnummer", id: "accountId" },
        { name: "Kontonamn", id: "accountName" },
        { name: "Organisationsnummer", id: "organizationNumber" },
        { name: "Organisationsnamn", id: "organizationName" },
      ],
      admin: [
        { name: "Mejladress", id: "emailAddress" },
        { name: "Personnummer", id: "socialSecurityNumber" },
        { name: "Namn (för- eller efternamn)", id: "name" },
        { name: "Mobilnummer", id: "phoneNumber" },
      ],
      traveller: [
        { name: "Namn", id: "name" },
        { name: "Reskortsnummer", id: "cardNumber" },
        { name: "Mobilnummer", id: "phoneNumber" },
        { name: "Kundnummer", id: "accountId" },
      ],
    };
    return options[type];
  };

  const setDefaultSearchOption = (id) => {
    state.selectedCategory = id;
  };

  const getSearchPath = (type) => {
    const paths = {
      account: "GetAccountInfo",
      admin: "GetUserInfo",
      traveller: "GetTravellerInfo",
    };
    return paths[type];
  };

  const clearInputError = () => {
    state.inputError = "";
  };

  const setLoading = (value) => {
    state.loading = value;
  };

  const setSearchError = (setError, error) => {
    state.resultError = setError
      ? `Hoppsan, just nu verkar det som att något inte stämmer. Vänligen testa senare igen! ${error}`
      : "";
  };

  return {
    state,
    searchData,
    getSearchOptions,
    isValidSearch,
    clearSearch,
    clearInputError,
    updateAdminSearchResult,
    updateTravellerSearchResult,
    setDefaultSearchOption,
    updateSearchResult,
    setLoading,
    setSearchError,
  };
};
