import {
  loginRequest,
  msalInstance,
} from "skanetrafiken-authenticationservice";
const scopes = process.env.VUE_APP_AUTH_SCOPES;
const auth = {
  clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
  redirectUri: process.env.VUE_APP_AUTH_REDIRECT_URI,
  postLogoutRedirectUri: process.env.VUE_APP_AUTH_POSTLOGOUT_REDIRECT_URI,
  authority: process.env.VUE_APP_AUTH_AUTHORITY,
};

export const authServiceLoginRequest = loginRequest(scopes);
export const instance = msalInstance(auth);
