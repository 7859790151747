export const useTable = (tableStructure) => {
  const getTableRowFormat = (item) => {
    return Object.values(getTableStructure(item)).map((value) => {
      return typeof value === "object" //If the value is an object(component) we want to keep it as it is and not convert to string
        ? value
        : { value: value.toString() };
    });
  };

  const getTableStructure = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null) obj[key] = "";
    });
    const structure = tableStructure(obj);
    return structure;
  };

  const sortTable = (arrayToSort, sortKey, order) => {
    const sortingMethod = order === "asc" ? ascendingSort : decendingSort;
    const newArray = arrayToSort.sort((a, b) => {
      const a1 = a[sortKey];
      const toSort1 = typeof a1 === "string" ? a1.toLowerCase() : a1;
      const b1 = b[sortKey];
      const toSort2 = typeof b1 === "string" ? b1.toLowerCase() : b1;
      return sortingMethod(toSort1, toSort2);
    });
    return newArray;
  };

  const ascendingSort = (a, b) => {
    return a > b ? 1 : -1;
  };

  const decendingSort = (a, b) => {
    return a < b ? 1 : -1;
  };

  return {
    getTableStructure,
    getTableRowFormat,
    sortTable,
  };
};
