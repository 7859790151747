export default {
  accountName: [
    {
      key: "maxLength",
      message: "Kontonamn får inte vara mer än 100 tecken långt.",
      id: "accountNameLength",
    },
  ],
  ugNumber: [
    {
      key: "maxLength",
      message: "UG nummer får inte vara mer än 100 tecken långt.",
      id: "ugNumberLength",
    },
    {
      key: "numeric",
      message: "UG nummer måste vara siffror",
      id: "numeric",
    },
    {
      key: "required",
      message: "UG nummer är obligatoriskt.",
      id: "ugNumberRequired",
    },
  ],
};
