export default {
  street: [
    {
      key: "required",
      message: "Adress är obligatoriskt.",
      id: "streetRequired",
    },
    {
      key: "maxLength",
      message: "Adress får inte vara mer än 50 tecken långt.",
      id: "streetLength",
    },
  ],
  postalCode: [
    {
      key: "required",
      message: "Postnummer är obligatoriskt.",
      id: "postalCodeRequired",
    },
    {
      key: "postalCodeFormat",
      message:
        "Felaktigt format på postnummer. Vänligen ange ett nummer enligt formatet xxxxx eller xxx xx.",
      id: "postalCodeFormat",
    },
  ],
  city: [
    {
      key: "required",
      message: "Ort är obligatoriskt.",
      id: "cityRequired",
    },
    {
      key: "maxLength",
      message: "Ort får inte vara mer än 25 tecken långt.",
      id: "cityLength",
    },
  ],
  co: [
    {
      key: "maxLength",
      message: "C/O får inte vara mer än 50 tecken långt.",
      id: "coLength",
    },
  ],
};
