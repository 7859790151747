<template>
  <div class="st-bo-error">
    <warning />
    <StHeadline :level="4">{{ text }}</StHeadline>
    <span v-if="errorMessage" class="st-bo-error__error-msg">
      {{ errorMessage }}
    </span>
    <span v-if="errorCode" class="st-bo-error__error-code">
      Error code: {{ errorCode }}
    </span>
  </div>
</template>
<script>
import Warning from "@/components/common/Warning.vue";
import { StHeadline } from "skanetrafiken";

export default {
  name: "st-bo-error",
  components: {
    Warning,
    StHeadline,
  },
  props: {
    text: String,
    errorMessage: String,
    errorCode: String,
  },
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-error {
  display: inline-flex;
  padding: rem-calc(8);
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: rem-calc(8);

  & > * {
    margin-bottom: rem-calc(8);
  }
}
</style>
