export default {
  invoiceEmail: [
    {
      key: "required",
      message: "Mejladress är obligatoriskt.",
      id: "emailRequired",
    },
    {
      key: "emailFormat",
      message: "Felaktigt format på mejladressen.",
      id: "emailFormat",
    },
    {
      key: "maxLength",
      message: "Mejladressen får inte vara mer än 100 tecken långt.",
      id: "emailLength",
    },
  ],
  peppolId: [
    {
      key: "required",
      message: "Peppol ID är obligatoriskt.",
      id: "peppolIdRequired",
    },
    {
      key: "peppolIdValidation",
      message:
        "Felaktigt PEPPOL ID. Formatet för svenska organisationer måste vara enligt 0088:GLN nr alternativt 0007:ORG.NR",
      id: "peppolIdValidation",
    },
  ],
  reference: [
    {
      key: "required",
      message: "Fakturareferens är obligatoriskt.",
      id: "referenceRequired",
    },
    {
      key: "maxLength",
      message: "Referens får inte vara mer än 32 tecken långt.",
      id: "referenceLength",
    },
  ],
};
