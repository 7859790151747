import { useUser } from "@/composables/useUser";
import { markRaw } from "vue";
import StDelete from "@/components/common/Delete.vue";

export const useTable = () => {
  const user = useUser();
  const getTableInfo = (type, obj) => {
    return Object.values(getTableObjectStructure(type, obj)).map((value) => {
      return typeof value === "object" //Om värdet är ett objekt (delete) vill vi ha det som det är och inte toString
        ? value
        : { value: value.toString() };
    });
  };

  const getTableObjectStructure = (type, obj) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null) obj[key] = "";
    });

    const structures = {
      traveller: {
        accountId: obj.accountId,
        phoneNumber: obj.phoneNumber,
        cardNumber: obj.cardNumber,
        name: obj.name,
        subscription: obj.subscriptions,
        inAppPurchases: obj.inAppPurchases ? "Ja" : "Nej",
        delete: {
          component: markRaw(StDelete),
          componentProps: {
            disabled: !user.state.isAdmin,
          },
          clickable: true,
        },
      },
      admin: {
        email: obj.email,
        totalAccounts: obj.totalAccounts,
        ssn: obj.ssn,
        name: `${obj.givenName} ${obj.surname}`,
        phoneNumber: obj.phoneNumber,
        blocked: obj.blocked ? "Spärrad" : "Ja",
      },
      adminAccounts: {
        accountId: obj.accountId,
        organizationNumber: obj.organizationNumber,
        organizationName: obj.companyName,
        accountName: obj.displayName,
        delete: {
          component: markRaw(StDelete),
          componentProps: {
            disabled: !user.state.isAdmin,
          },
          clickable: true,
        },
      },
      accountTraveller: {
        phoneNumber: obj.phoneNumber,
        cardNumber: obj.cardNumber,
        name: obj.name,
        subscription: obj.subscription ? "Ja" : "Nej",
        inAppPurchases: obj.inAppPurchases ? "Ja" : "Nej",
        delete: {
          component: markRaw(StDelete),
          componentProps: {
            disabled: !user.state.isAdmin,
          },
          clickable: true,
        },
      },
      account: obj,
    };
    return structures[type];
  };

  const getTableIndex = (key) => {
    const indexes = {
      email: 0,
      totalAccounts: 1,
      phoneNumber: 4,
      blocked: 5,
    };
    return indexes[key];
  };

  const getTableHeaders = (type) => {
    const headers = {
      admin: [
        { name: "Mejladress", isSortable: false },
        { name: "Antal konton", isSortable: false },
        { name: "Personnummer", isSortable: false },
        { name: "Namn", isSortable: false },
        { name: "Mobilnummer", isSortable: false },
        { name: "Aktiv", isSortable: false },
      ],
      traveller: [
        { name: "Kundnummer", isSortable: false },
        { name: "Mobilnummer", isSortable: false },
        { name: "Reskortsnummer", isSortable: false },
        { name: "Namn", isSortable: false },
        { name: "Abonnemang", isSortable: false },
        { name: "Köp i appen", isSortable: false },
        { name: "Ta bort", isSortable: false },
      ],
      adminAccounts: [
        { name: "Kundnummer", isSortable: false },
        { name: "Org.nummer", isSortable: false },
        { name: "Org.namn", isSortable: false },
        { name: "Kontonamn", isSortable: false },
        { name: "Ta bort", isSortable: false },
      ],
      accountTraveller: [
        { name: "Mobilnummer", isSortable: false },
        { name: "Reskortsnummer", isSortable: false },
        { name: "Namn", isSortable: false },
        { name: "Abonnemang", isSortable: false },
        { name: "Köp i appen", isSortable: false },
        { name: "Ta bort", isSortable: false },
      ],
      accountAdmin: [
        { name: "Mejladress", isSortable: false },
        { name: "Totalt antal konton", isSortable: false },
        { name: "Personnummer", isSortable: false },
        { name: "Namn", isSortable: false },
        { name: "Mobilnummer", isSortable: false },
        { name: "Aktiv", isSortable: false },
      ],
    };
    return headers[type];
  };

  const updateTableInfo = (table, correctValue, updateValues, specialValue) => {
    updateValues.forEach((value) => {
      table[getTableIndex(value)] = {
        value: specialValue || correctValue[value],
      };
    });
  };

  return {
    getTableInfo,
    getTableObjectStructure,
    getTableHeaders,
    getTableIndex,
    updateTableInfo,
  };
};
