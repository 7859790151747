import { maxLength, numeric, required } from "@vuelidate/validators";

export default {
  accountName: {
    maxLength: maxLength(100),
  },
  ugNumber: {
    maxLength: maxLength(100),
    required,
    numeric,
  },
};
