<template>
  <StModal size="medium" :on-close="() => emit(CLOSE)">
    <div class="st-account-confirmation-modal">
      <StHeadline :level="2">{{ title }}</StHeadline>
      <p v-if="description" class="st-account-confirmation-modal__paragraph">
        {{ description }}
      </p>
      <ModalAccountDetails
        :account-name="account.accountName"
        :email="account.email"
        :organization-name="account.companyName"
      />
      <st-button primary full-width @click="emit(CLOSE)"> Stäng </st-button>
    </div>
  </StModal>
</template>

<script setup>
import { StModal, StButton, StHeadline } from "skanetrafiken";
import { CLOSE } from "@/constants";
import ModalAccountDetails from "../common/ModalAccountDetails.vue";

const emit = defineEmits(["close"]);

defineProps({
  title: String,
  account: Object,
  description: String,
});
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-account-confirmation-modal {
  text-align: center;
  font-family: var(--font-primary);
  max-width: rem-calc(600);

  & > :not(:last-child) {
    margin-bottom: rem-calc(24);
  }

  &__paragraph {
    color: var(--text-color-primary);
  }
}
</style>
