<template>
  <StModal size="medium" :on-close="() => emit(CLOSE)">
    <div class="st-domain-confirmation-modal">
      <StHeadline :level="2">{{ title }}</StHeadline>
      <p v-if="description" class="st-domain-confirmation-modal__paragraph">
        {{ description }}
      </p>
      <ModalDomainDetails
        :domain="domain"
        :organization-number="organizationNumber"
        :organization-name="organizationName"
      />
      <StButton primary full-width @click="emit(CLOSE)"> Stäng </StButton>
    </div>
  </StModal>
</template>

<script setup>
import { StModal, StButton, StHeadline } from "skanetrafiken";
import { CLOSE } from "@/constants";
import ModalDomainDetails from "../common/ModalDomainDetails.vue";

const emit = defineEmits(["close"]);

defineProps({
  title: String,
  description: String,
  domain: String,
  organizationName: String,
  organizationNumber: String,
});
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/functions.scss";
.st-domain-confirmation-modal {
  text-align: center;
  font-family: var(--font-primary);

  & > :not(:last-child) {
    margin-bottom: rem-calc(24);
  }

  &__paragraph {
    color: var(--text-color-primary);
  }
}
</style>
