export default {
  email: [
    {
      key: "required",
      message: "Mejladress är obligatoriskt.",
      id: "emailRequired",
    },
    {
      key: "emailFormat",
      message: "Felaktigt format på mejladressen.",
      id: "emailFormat",
    },
    {
      key: "maxLength",
      message: "Mejladressen får inte vara mer än 100 tecken långt.",
      id: "emailLength",
    },
  ],
  phoneNumber: [
    {
      key: "required",
      message: "Mobilnummer är obligatoriskt.",
      id: "emailRequired",
    },
    {
      key: "numeric",
      message: "Felaktigt format på mobilnummer, kan endast vara siffor",
      id: "numeric",
    },
    {
      key: "maxLength",
      message: "Mobilnummer får inte vara mer än 30 tecken långt.",
      id: "mobileMaxLength",
    },
    {
      key: "minLength",
      message: "Mobilnummer får inte vara mindre än 6 tecken långt.",
      id: "mobileMinLength",
    },
  ],
};
