<template>
  <div class="st-bo-account-travellers">
    <st-spinner
      v-if="isLoading.fetchingTravellers"
      loading-message="Laddar..."
      class="st-bo-account-travellers__spinner"
    />
    <error
      v-else-if="error.text"
      :text="error.text"
      :error-message="error.errMessage"
      :error-code="error.errCode"
    />
    <div v-else class="st-bo-account-travellers__content">
      <span>Antal resenärer: {{ travellers.length }}</span>
      <div class="st-bo-account-travellers__content__handle">
        <span>Antal inbjudna till köp i app: {{ travellersInvited }}</span>
        <st-button
          primary
          @click="openAddTravellerModal"
          tabindex="0"
          :disabled="isNotAdmin"
        >
          Lägg till resenär
        </st-button>
      </div>
      <StHeadline :level="4">Resenärer</StHeadline>
      <st-table
        :table-data="travellers"
        :headers="headers"
        @on-item-click="rowClick"
      />
      <add-traveller-modal
        ref="addTravellerModal"
        @close="closeAddTravellerModal"
        @add="handleAdd"
        :is-loading="isLoading.addTraveller"
      />
      <remove-modal
        ref="removeTravellerModal"
        title="Ta bort resenär"
        :text="`Är du säker på att du vill ta bort ${selectedTraveller.name} från ${accountName}?`"
        @remove="handleRemove"
        @close="closeRemoveTravellerModal"
        :is-loading="isLoading.removeTraveller"
      />
      <confirmation-modal
        ref="confirmationModal"
        :title="showConfirmationModal.title"
        :status="showConfirmationModal.status"
        :error="showConfirmationModal.error"
        @close="closeConfirmationModal"
      />
    </div>
  </div>
</template>
<script>
import { StTable, StButton, StSpinner, StHeadline } from "skanetrafiken";
import { useAccount } from "@/composables/useAccount";
import { useUser } from "@/composables/useUser";
import { useTraveller } from "@/composables/useTraveller";
import { useTable } from "@/composables/useTable";
import { useError } from "@/composables/useError";
import { ref, computed, onMounted, reactive } from "vue";
import AddTravellerModal from "@/components/modals/AddTravellerModal.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import RemoveModal from "@/components/modals/RemoveModal.vue";
import Error from "@/components/common/Error.vue";
import { ACCOUNT_TRAVELLER, GENERIC_ERROR_MSG } from "@/constants";
import { useModal } from "@/composables/useModal";

export default {
  name: "st-bo-account-travellers",
  components: {
    StButton,
    StTable,
    StSpinner,
    StHeadline,
    AddTravellerModal,
    ConfirmationModal,
    RemoveModal,
    Error,
  },
  setup() {
    const account = useAccount();
    const user = useUser();
    const traveller = useTraveller();
    const table = useTable();
    const errorMessages = useError();
    const error = ref({ text: "", errMessage: "", errCode: "" });
    const showConfirmationModal = ref({
      title: "",
      status: false,
      error: {},
    });

    const selectedTraveller = ref({ id: undefined, name: "" });

    const isLoading = reactive({
      fetchingTravellers: false,
      addTraveller: false,
      removeTraveller: false,
    });

    const addTravellerModal = ref(null);
    const confirmationModal = ref(null);
    const removeTravellerModal = ref(null);

    const [openAddTravellerModal, closeAddTravellerModal] =
      useModal(addTravellerModal);
    const [openConfirmationModal, closeConfirmationModal] =
      useModal(confirmationModal);
    const [openRemoveTravellerModal, closeRemoveTravellerModal] =
      useModal(removeTravellerModal);

    onMounted(async () => {
      try {
        isLoading.fetchingTravellers = true;
        await account.getAccountTravellers();
        isLoading.fetchingTravellers = false;
      } catch (err) {
        isLoading.fetchingTravellers = false;
        error.value = {
          text: "Det går inte att hämta resenärer för detta konto just nu. Vänligen testa senare igen!",
          errMessage: errorMessages.getErrorMessage(
            err.response.data.code || err.code
          ),
          errCode: err.response.data.code || err.code,
        };
      }
    });

    const handleAdd = async (newTraveller) => {
      try {
        isLoading.addTraveller = true;
        await traveller.addTraveller(newTraveller);
        isLoading.addTraveller = false;
        closeAddTravellerModal();
        showConfirmation("Resenär tillagd.");
      } catch (err) {
        isLoading.addTraveller = false;
        closeAddTravellerModal();
        showConfirmation(GENERIC_ERROR_MSG, err.response.data || err);
      }
    };

    const handleRemove = async () => {
      try {
        isLoading.removeTraveller = true;
        await traveller.removeTraveller(
          selectedTraveller.value.id,
          ACCOUNT_TRAVELLER
        );
        isLoading.removeTraveller = false;
        closeRemoveTravellerModal();
        showConfirmation("Resenär borttagen.");
      } catch (err) {
        isLoading.removeTraveller = false;
        closeRemoveTravellerModal();
        showConfirmation(GENERIC_ERROR_MSG, err.response.data || err);
      }
    };

    const rowClick = (index) => {
      if (user.state.isAdmin) {
        selectedTraveller.value = {
          id: account.state.account.travellers[index].travellerId,
          name: account.state.account.travellers[index].name,
        };
        openRemoveTravellerModal();
      }
    };

    const showConfirmation = (title, error) => {
      showConfirmationModal.value = {
        title,
        status: !error,
        error: error ? error : {},
      };
      openConfirmationModal();
    };

    return {
      rowClick,
      headers: table.getTableHeaders(ACCOUNT_TRAVELLER),
      showConfirmationModal,
      handleAdd,
      handleRemove,
      travellers: computed(() =>
        account.state.account.travellers.map((traveller) => traveller.tableInfo)
      ),
      error,
      travellersInvited: computed(
        () => account.state.account.invitedTravellers
      ),
      isNotAdmin: computed(() => !user.state.isAdmin),
      isLoading: computed(() => isLoading),
      accountName: computed(
        () => account.state.account.accountName || "detta konto"
      ),
      selectedTraveller,
      addTravellerModal,
      openAddTravellerModal,
      closeAddTravellerModal,
      confirmationModal,
      openConfirmationModal,
      closeConfirmationModal,
      removeTravellerModal,
      openRemoveTravellerModal,
      closeRemoveTravellerModal,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";

.st-bo-account-travellers {
  display: flex;
  flex-direction: column;
  margin-top: rem-calc(16);
  font-family: $source-sans-pro;

  &__content {
    display: flex;
    flex-direction: column;
    text-align: start;
    font-size: rem-calc(18);

    &__handle {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: rem-calc(22) 0;
      font-size: rem-calc(18);

      & > :first-child {
        margin-right: rem-calc(80);
      }
    }
  }

  &__spinner {
    margin-top: rem-calc(16);
  }

  &__error-text {
    margin-top: rem-calc(16);
    text-align: start;
  }
}
</style>
