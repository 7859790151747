<template>
  <button :class="classList" :disabled="disabled">
    <StIcon
      :name="iconName"
      :icon-props="{ color: 'var(--border-color-primary)' }"
    />
    {{ text }}
  </button>
</template>
<script setup>
import { StIcon } from "skanetrafiken";
import { computed } from "vue";

const props = defineProps({
  disabled: Boolean,
  iconName: {
    type: String,
    default: "st-decline",
  },
  text: {
    type: String,
    default: "Neka",
  },
});

const classList = computed(() => {
  const btn = "st-table-btn";
  return [btn, props.disabled && `${btn}--disabled`];
});
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-table-btn {
  display: flex;
  font-family: var(--font-primary);
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: rem-calc(8);
  border-radius: rem-calc(8);
  transition: border 0.1s linear;
  color: var(--text-color-quinary);
  font-size: rem-calc(16);
  font-weight: 600;
  border: rem-calc(2) solid transparent;
  text-align: start;

  & > :first-child {
    margin-right: rem-calc(8);
  }

  &:focus {
    outline: none;
    border-color: var(--border-color-primary);
  }

  &:not([disabled]):hover {
    border-color: var(--border-color-primary);
  }

  &--disabled {
    cursor: auto;
    opacity: 0.3;
  }
}
</style>
