<template>
  <StModal size="medium" :on-close="() => $emit(CLOSE)">
    <div class="st-bo-confirmation-modal">
      <div v-if="status">
        <checkmark class="st-bo-confirmation-modal__icon" />
        <span class="st-bo-confirmation-modal__text">
          {{ title }}
        </span>
      </div>
      <error
        v-else
        :text="title"
        :error-message="errorMessages.getErrorMessage(error.code)"
        :error-code="error.code"
      />
      <st-button full-width @click="$emit(CLOSE)" tabindex="0">
        Stäng
      </st-button>
    </div>
  </StModal>
</template>

<script>
import { StModal, StButton } from "skanetrafiken";
import Checkmark from "@/components/common/Checkmark.vue";
import { CLOSE } from "@/constants";
import Error from "@/components/common/Error.vue";
import { useError } from "@/composables/useError";
export default {
  name: "st-bo-confirmation-modal",
  components: {
    StModal,
    StButton,
    Checkmark,
    Error,
  },
  props: {
    status: Boolean,
    title: String,
    error: Object,
  },
  setup() {
    const errorMessages = useError();
    return { CLOSE, errorMessages };
  },
};
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-confirmation-modal {
  &__icon {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__text {
    display: block;
    text-align: center;
    font-size: rem-calc(30);
    font-weight: bold;
    padding: rem-calc(24) 0 rem-calc(32) 0;
    color: $grey-dark;
  }
}
</style>
