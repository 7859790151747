<template>
  <div>
    <div class="st-bo-dropdown-menu">
      <ul class="st-bo-dropdown-menu__list">
        <li data-text="Konto" class="st-bo-dropdown-menu__list__item">
          <router-link
            @click="closeDropDown()"
            class="st-bo-dropdown-menu__list__item__link"
            to="/"
            :class="{
              'active-link': $route.matched.some(
                ({ name }) => name === 'AccountDetails'
              ),
            }"
          >
            Konto
          </router-link>
        </li>
        <li data-text="Administratörer" class="st-bo-dropdown-menu__list__item">
          <router-link
            @click="closeDropDown()"
            class="st-bo-dropdown-menu__list__item__link"
            to="/admins"
          >
            Administratörer
          </router-link>
        </li>
        <li data-text="Resenärer" class="st-bo-dropdown-menu__list__item">
          <router-link
            @click="closeDropDown()"
            class="st-bo-dropdown-menu__list__item__link"
            to="/travellers"
          >
            Resenärer
          </router-link>
        </li>
        <li
          v-if="hasWhitelisting"
          data-text="Domäner"
          class="st-bo-dropdown-menu__list__item"
        >
          <router-link
            @click="closeDropDown()"
            class="st-bo-dropdown-menu__list__item__link"
            to="/domains"
          >
            Domäner
          </router-link>
        </li>
        <li class="st-bo-dropdown-menu__list__item" @click="logout()">
          <span class="st-bo-dropdown-menu__list__item__logout">Logga ut</span>
        </li>
      </ul>
    </div>
    <div class="outside" @click="outsideClick()"></div>
  </div>
</template>
<script>
import { useMsal } from "skanetrafiken-authenticationservice";
import { toRefs, getCurrentInstance } from "vue";
import config from "~/config";

export default {
  name: "st-bo-dropdown-menu",
  props: {
    close: {
      type: Function,
    },
  },

  setup(props) {
    const { instance } = useMsal(getCurrentInstance, toRefs);
    const systemName = process.env.VUE_APP_SYSTEM_APP;

    const logout = () => {
      instance.logoutRedirect();
    };

    const outsideClick = () => {
      props.close();
    };

    const closeDropDown = () => {
      props.close();
    };

    return {
      logout,
      logoBySystem: `logo-${systemName}`,
      imgSrc: `/logo-${
        systemName === "st" ? `${systemName}.jpg` : `${systemName}.png`
      }`,
      outsideClick,
      closeDropDown,
      hasWhitelisting: config.APPROVAL_ENABLED,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";

.st-bo-dropdown-menu {
  position: absolute;
  z-index: 2;
  background-color: white;
  right: 0;
  border-radius: rem-calc(12) 0 rem-calc(12) rem-calc(12);
  box-shadow: 0 rem-calc(8) rem-calc(24) rem-calc(-5) rgba(0, 0, 0, 0.1242);
  font-size: 1.125rem;

  &__list {
    &__item {
      &__link {
        display: block;
        margin: rem-calc(15);
        padding: rem-calc(20) rem-calc(20);
        border: $orange $border-width solid;
        border-radius: $border-radius;
        text-decoration: none;

        &:hover {
          background-color: $bright-orange;
        }
      }

      &__logout {
        margin: rem-calc(15);
        display: block;
        color: $dark-grey;
        padding: rem-calc(20) rem-calc(10);
        border-radius: $border-radius;
        background-color: $orange;

        &:hover {
          background-color: $dark-orange;
          cursor: pointer;
        }
      }
    }
  }
}

.outside {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;
}
</style>
