<template>
  <StModal :on-close="() => $emit(CLOSE)">
    <div class="st-bo-add-admin-modal">
      <StHeadline :level="2">Lägg till administratör</StHeadline>
    </div>
    <div class="st-bo-add-admin-modal__input">
      <st-form-group
        label="Mejladress"
        required
        :validation="v$.email"
        :validation-errors="validationErrors.email"
      >
        <st-input
          id="add-admin-modal-input-mail"
          v-model="newAdmin.email"
          type="text"
          placeholder="Mejladress"
          tabindex="0"
          required
          :blur="() => handleBlur()"
          :invalid="v$ && v$.email && v$.email.$error"
        />
      </st-form-group>
    </div>
    <div class="st-bo-add-admin-modal__buttons">
      <st-button full-width @click="$emit(CLOSE)" tabindex="0">
        Avbryt
      </st-button>
      <st-button
        primary
        full-width
        @click="addAdmin"
        tabindex="0"
        :is-loading="isLoading"
      >
        Lägg till
      </st-button>
    </div>
  </StModal>
</template>

<script>
import {
  StModal,
  StButton,
  StInput,
  StFormGroup,
  StHeadline,
} from "skanetrafiken";
import { ref } from "vue";
import AddAdminValidations from "@/validators/add-admin-validations";
import AddAdminValidationsErrors from "@/validators/add-admin-validations-errors";
import { useVuelidate } from "@vuelidate/core";
import { CLOSE } from "@/constants";
export default {
  name: "st-bo-add-admin-modal",
  components: {
    StModal,
    StButton,
    StInput,
    StFormGroup,
    StHeadline,
  },
  props: {
    isLoading: Boolean,
  },
  setup(_, { emit }) {
    const newAdmin = ref({ email: "" });
    const v$ = useVuelidate(AddAdminValidations, newAdmin);

    const handleBlur = () => {
      v$.value.email.$touch();
    };

    const addAdmin = () => {
      v$.value.$touch();
      const errors = v$.value.$errors;
      if (errors.length) return;
      emit("add", newAdmin.value.email);
    };

    return {
      newAdmin,
      addAdmin,
      v$,
      handleBlur,
      validationErrors: AddAdminValidationsErrors,
      CLOSE,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-add-admin-modal {
  &__title {
    text-align: center;
  }

  &__input {
    margin: rem-calc(30) 0;
  }

  &__buttons {
    display: flex;
    flex-direction: row;

    & > :last-child {
      margin-left: rem-calc(10);
    }
  }
}
</style>
