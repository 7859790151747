export const ACCOUNT = "account";
export const ADMIN = "admin";
export const TRAVELLER = "traveller";
export const ADMIN_ACCOUNTS = "adminAccounts";
export const ACCOUNT_TRAVELLER = "accountTraveller";
export const ACCOUNT_ADMIN = "accountAdmin";

export const ACCOUNT_CREATED = "createdAccount";
export const ACCOUNT_APPLICATION = "accountApplication";
export const ACCOUNT_VERIFICATION = "accountVerification";

export const UPDATE_ADMIN_INFO = "adminInfo";
export const UPDATE_ADMIN_BLOCKED = "blocked";
export const UPDATE_ADMIN_TOTAL_ACCOUNTS = "accountCount";

export const SELECTED_CATEGORY = "selectedCategory";
export const SEARCH_QUERY = "searchQuery";

export const CLOSE = "close";

export const GENERIC_ERROR_MSG = "Oops, det verkar som något gick snett!";
