<template>
  <StModal size="medium" :on-close="() => $emit(CLOSE)">
    <div class="st-bo-block-account-modal">
      <StHeadline :level="2">{{ actionTitle }} konto</StHeadline>
      <p class="st-bo-block-account-modal__paragraph">
        Är du säker på att du vill
        {{ actionTitle.toLowerCase() }}
        {{ accountName }}?
      </p>
      <st-checkbox
        v-model="blockAllChecked"
        class="st-bo-block-account-modal__checkbox"
        @click="() => (blockAllChecked = !blockAllChecked)"
        tabindex="0"
        :aria-label="checkboxText"
        icon-description=""
      >
        {{ checkboxText }}
      </st-checkbox>
      <p v-if="blockAllChecked" class="st-bo-block-account-modal__warning">
        {{ warningText }}
      </p>
      <div class="st-bo-block-account-modal__buttons">
        <st-button full-width @click="$emit(CLOSE)" tabindex="0">
          Avbryt
        </st-button>
        <st-button
          primary
          fullWidth
          tabindex="0"
          @click="$emit('open-confirm-modal', blockAllChecked)"
        >
          {{ `${actionTitle} ${blockAllChecked ? "konton" : "konto"}` }}
        </st-button>
      </div>
    </div>
  </StModal>
</template>

<script>
import { StModal, StButton, StCheckbox, StHeadline } from "skanetrafiken";
import { onUpdated, ref, computed } from "vue";
import { CLOSE } from "@/constants";
export default {
  name: "st-bo-block-account-modal",
  components: {
    StModal,
    StButton,
    StCheckbox,
    StHeadline,
  },
  props: {
    accountName: {
      type: String,
      default: "detta konto",
    },
    organizationNumber: String,
    isBlocked: Boolean,
  },
  setup(props) {
    const blockAllChecked = ref(false);

    onUpdated(() => {
      blockAllChecked.value = false;
    });

    return {
      blockAllChecked,
      actionTitle: computed(() => (props.isBlocked ? "Aktivera" : "Spärra")),
      checkboxText: computed(
        () => `${props.isBlocked ? `Aktivera alla spärrade ` : `Spärra alla`} 
        konton under organisationsnummer ${props.organizationNumber}`
      ),
      warningText: computed(
        () => `Du kommer ${
          props.isBlocked
            ? "aktivera samtliga konton som är spärrade"
            : "spärra samtliga konton"
        }
        under organisationsnummer ${
          props.organizationNumber
        }. Är du säker på detta?`
      ),
      CLOSE,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-block-account-modal {
  text-align: center;

  &__paragraph {
    text-align: center;
    margin: rem-calc(30) 0;
  }

  &__warning {
    font-weight: bold;
    margin-bottom: rem-calc(30);
  }

  &__checkbox {
    text-align: left;
    margin-bottom: rem-calc(30);
  }

  &__buttons {
    display: flex;
    flex-direction: row;

    & > * {
      line-height: rem-calc(22);
    }

    & > :last-child {
      margin-left: rem-calc(10);
    }
  }
}
</style>
