<template>
  <main class="st-org-bo">
    <div v-if="isAuthorized">
      <app-navigation></app-navigation>
      <router-view v-slot="{ Component }">
        <keep-alive include="AccountView">
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </div>
    <div v-else-if="loading" class="st-org-bo__info">
      <st-spinner :loading-message="'Hämtar roller....'" />
    </div>
    <div v-else class="st-org-bo__info">
      <StHeadline :level="2">
        Du har inte behörighet till denna applikationen, kontakta
        {{ getOrganisationSystemName() }}s administratörer.
      </StHeadline>
    </div>
  </main>
</template>
<script>
import AppNavigation from "@/components/AppNavigation";
import { onMounted, ref, computed } from "vue";
import { useUser } from "@/composables/useUser";
import { useAccount } from "@/composables/useAccount";
import { StSpinner, StHeadline } from "skanetrafiken";
import { authServiceLoginRequest, instance } from "./azure-ad.js";
import config from "~/config";

const accessTokenRequest = {
  scopes: authServiceLoginRequest.scopes,
};

export default {
  components: {
    AppNavigation,
    StSpinner,
    StHeadline,
  },
  setup() {
    const user = useUser();
    const account = useAccount();

    const isAuthorized = ref(false);
    const systemName = process.env.VUE_APP_SYSTEM_APP;

    onMounted(async () => {
      try {
        user.setLoading(true);
        const { accessToken } = await instance.acquireTokenSilent(
          accessTokenRequest
        );
        isAuthorized.value = user.getRole(accessToken);
        if (isAuthorized.value && config.APPROVAL_ENABLED) {
          await account.fetchAccountApplications();
        }
        user.setLoading(false);
      } catch (err) {
        if (
          err.errorCode === "interaction_required" ||
          err.errorCode === "login_required" ||
          err.errorCode === "no_account_error"
        ) {
          user.setLoading(false);
          instance.loginRedirect(authServiceLoginRequest);
        }
      }
    });

    const getOrganisationSystemName = () => {
      return systemName === "st"
        ? "Skånetrafiken"
        : systemName === "bt"
        ? "Blekingetrafiken"
        : "Östgötatrafiken";
    };

    return {
      isAuthorized,
      loading: computed(() => user.state.loading),
      systemName,
      getOrganisationSystemName,
    };
  },
};
</script>

<style lang="scss">
@import "main.scss";
@import "~skanetrafiken/dist/scss/main.scss";
.st-org-bo {
  background: $white;
  text-align: center;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    & > :not(:last-child) {
      margin-bottom: rem-calc(16);
    }
  }
}
</style>
