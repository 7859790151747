<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.4801 6.84L36.6161 30.022C36.9609 30.6305 37.1402 31.3188 37.136 32.0182C37.1317 32.7176 36.9442 33.4036 36.5921 34.0079C36.2399 34.6122 35.7355 35.1136 35.1291 35.4621C34.5227 35.8106 33.8355 35.994 33.1361 35.994H6.86414C6.16474 35.994 5.47757 35.8106 4.87117 35.4621C4.26477 35.1136 3.76035 34.6122 3.40822 34.0079C3.05609 33.4036 2.86856 32.7176 2.86433 32.0182C2.86011 31.3188 3.03934 30.6305 3.38414 30.022L16.5201 6.842C16.869 6.22641 17.3749 5.71438 17.9863 5.35814C18.5976 5.0019 19.2926 4.81421 20.0001 4.81421C20.7077 4.81421 21.4026 5.0019 22.014 5.35814C22.6253 5.71438 23.1313 6.22641 23.4801 6.842V6.84Z"
      fill="#E14949"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 14H22V23.412H18V14ZM18 26.352H22V29.882H18V26.352Z"
      fill="white"
    />
  </svg>
</template>
