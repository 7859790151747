import api from "@/axios";
import config from "~/config";

export default {
  GET: {
    NOT_APPROVED_ACCOUNTS: async () => {
      const { data } = await api.get(`${config.NEW_ORG_URL}/accounttickets`);
      return data.accountTickets;
    },
    NOT_VERIFIED_ACCOUNTS: async (query) => {
      const { data } = await api.get(
        `${config.NEW_ORG_URL}/emailtickets/search/${query}`
      );
      return data.tickets;
    },
    ACCOUNT_VERIFICATION_DETAILS: async (id) => {
      const { data } = await api.get(
        `${config.NEW_ORG_URL}/emailtickets/${id}`
      );
      return data;
    },
    ACCOUNT_APPLICATION_DETAILS: async (id) => {
      const { data } = await api.get(
        `${config.NEW_ORG_URL}/accounttickets/${id}`
      );
      return data;
    },
    ORGANIZATION: async (orgNbr) => {
      const { data } = await api.get(
        `${config.BASE_URL}/GetAccountInfo?searchType=organizationNumber&searchValue=${orgNbr}`
      );
      return data.accounts;
    },
  },
  POST: {
    RESOLVE_ACCOUNT_TICKET: async (payload) => {
      try {
        const { data } = await api.post(
          `${config.NEW_ORG_URL}/accounttickets`,
          payload
        );
        return data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    RESEND_EMAIL_TICKET: async (id) => {
      try {
        const { data } = await api.post(
          `${config.NEW_ORG_URL}/emailtickets/adminresend`,
          { id }
        );
        return data.ticketId;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
  },
  PUT: {},
  DELETE: {
    DELETE_EMAIL_TICKET: async (id) => {
      try {
        const { data } = await api.delete(
          `${config.NEW_ORG_URL}/emailtickets/delete/${id}`
        );
        return data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
  },
  PATCH: {},
};
