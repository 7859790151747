<template>
  <div class="st-accounts-view">
    <StHeadline>Konto</StHeadline>
    <StTabs :tabs="tabs" font="primary">
      <template #createdAccount>
        <Search :search-type="ACCOUNT" />
        <AccountList
          :account-type="ACCOUNT_CREATED"
          :on-row-click="rowClick"
          :content="search.state.result"
          :has-empty-list="search.state.emptySearch"
        />
      </template>
      <template #accountApplication>
        <AccountList
          :account-type="ACCOUNT_APPLICATION"
          :on-row-click="rowClickApplication"
          :on-mount="onMountAccountApplication"
          :content="account.state.accountApplications"
          :has-empty-list="!account.state.accountApplications.length"
        />
      </template>
      <template #accountVerification>
        <div class="st-accounts-view__search-container">
          <QuickSearch
            v-model="accountVerificationSearch"
            @search="searchAccountVerifications"
            label-name="Sök på mejladress, organisation, kontonamn eller personnummer"
          />
        </div>
        <AccountList
          :account-type="ACCOUNT_VERIFICATION"
          :on-row-click="rowClickVerification"
          :content="account.state.accountVerifications"
          :has-empty-list="verificationSearchEmpty"
        />
      </template>
    </StTabs>
  </div>
</template>

<script setup>
import { StHeadline, StTabs } from "skanetrafiken";
import { computed, ref } from "vue";
import { useSearch } from "@/composables/useSearch";
import { useAccount } from "@/composables/useAccount";
import router from "@/router";
import AccountList from "@/components/AccountList.vue";
import Search from "@/components/Search.vue";
import {
  ACCOUNT,
  ACCOUNT_CREATED,
  ACCOUNT_APPLICATION,
  ACCOUNT_VERIFICATION,
} from "@/constants";
import QuickSearch from "@/components/QuickSearch.vue";
import config from "~/config";

const search = useSearch();
const account = useAccount();

const verificationSearchEmpty = ref(false);

const accountVerificationSearch = ref("");

const rowClick = (_, index) => {
  account.setSelectedAccount(search.state.result[index].accountId);
  router.push(`/account-details/${account.state.account.accountId}`);
};

const rowClickVerification = (_, index) => {
  const id = account.state.accountVerifications[index].id;
  router.push(`/account-verification-details/${id}`);
};

const rowClickApplication = (_, index) => {
  const id = account.state.accountApplications[index].id;
  router.push(`/account-application-details/${id}`);
};

const onMountAccountApplication = async () => {
  search.setLoading(true);
  try {
    await account.fetchAccountApplications();
  } catch (err) {
    search.setSearchError(true, err);
  } finally {
    search.setLoading(false);
  }
};

const searchAccountVerifications = async () => {
  search.setLoading(true);
  try {
    const res = await account.fetchAccountVerifications(
      accountVerificationSearch.value
    );
    if (!res.length) {
      verificationSearchEmpty.value = true;
    }
  } catch (err) {
    search.setSearchError(true, err);
  } finally {
    search.setLoading(false);
  }
};

const tabs = computed(() => {
  const tabConfig = [
    {
      type: ACCOUNT_CREATED,
      name: "Konton",
    },
    {
      type: ACCOUNT_APPLICATION,
      name: "Kontoansökningar",
      iconName:
        account.state.accountApplications.length > 0
          ? "st-number-notification"
          : "",
      iconProps: { number: account.state.accountApplications.length },
      iconReversed: true,
    },
    {
      type: ACCOUNT_VERIFICATION,
      name: "Mejlverifieringar",
    },
  ];
  if (!config.APPROVAL_ENABLED) {
    return tabConfig.filter((tab) => tab.type !== ACCOUNT_APPLICATION);
  }
  return tabConfig;
});
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-accounts-view {
  padding: rem-calc(20);
  display: flex;
  flex-direction: column;
  text-align: start;

  & > :first-child {
    margin-bottom: rem-calc(24);
  }

  &__loader {
    padding-top: rem-calc(30);
  }

  &__count-text {
    align-self: flex-start;
    font-weight: bold;
    padding: rem-calc(8) 0;
  }

  &__search-container {
    max-width: rem-calc(650);
    padding-top: rem-calc(8);
    padding-bottom: rem-calc(24);
  }
}
</style>
