<template>
  <div class="st-account-application-actions">
    <TableButton
      :disabled="actionDisabled"
      @click="openApprovalModal"
      text="Godkänn"
      icon-name="st-accept"
    />
    <TableButton
      :disabled="actionDisabled"
      @click="openDenialModal(), (denialModalOpen = true)"
      text="Neka"
      icon-name="st-decline"
    />
  </div>
  <AccountDenialModal
    ref="denialModal"
    :account="account"
    :is-open="denialModalOpen"
    with-reason
    :error-message="errorMessage"
    :is-loading="isLoading"
    @on-denial="handleApplication"
    @close="handleDenialClose"
  />
  <AccountApprovalModal
    ref="approvalModal"
    :account="account"
    :error-message="errorMessage"
    :is-loading="isLoading"
    @on-approve="handleApplication"
    @close="closeApprovalModal(), (errorMessage = '')"
  />
  <AccountConfirmationModal
    ref="confirmationModal"
    :account="account"
    :title="confirmationInfo.title"
    @close="handleConfirmationClose"
  />
</template>
<script setup>
import { useModal } from "@/composables/useModal";
import { useUser } from "@/composables/useUser";
import AccountDenialModal from "./modals/AccountDenialModal.vue";
import AccountApprovalModal from "./modals/AccountApprovalModal.vue";
import AccountConfirmationModal from "./modals/AccountConfirmationModal.vue";
import { ref, computed } from "vue";
import Services from "@/services";
import { GENERIC_ERROR_MSG } from "@/constants";
import TableButton from "@/components/common/TableButton.vue";
import { useAccount } from "@/composables/useAccount";

const props = defineProps({
  account: Object,
});

const confirmationInfo = ref({
  title: "",
});

const isLoading = ref(false);

const errorMessage = ref("");

const denialModalOpen = ref(false);

const accountHandler = useAccount();
const user = useUser();

const denialModal = ref(null);
const approvalModal = ref(null);
const confirmationModal = ref(null);

const [openDenialModal, closeDenialModal] = useModal(denialModal);
const [openApprovalModal, closeApprovalModal] = useModal(approvalModal);
const [openConfirmationModal, closeConfirmationModal] =
  useModal(confirmationModal);

const actionDisabled = computed(() => !user.state.isAdmin);

const handleApplication = async (isApproved, reasonId) => {
  isLoading.value = true;
  errorMessage.value = "";
  try {
    const payload = {
      id: props.account.id,
      isApproved,
      reason: isApproved ? 5 : parseInt(reasonId),
      createdBy: user.state.email,
    };
    await Services.Account.POST.RESOLVE_ACCOUNT_TICKET(payload);
    isApproved ? closeApprovalModal() : handleDenialClose();
    confirmationInfo.value = {
      title: isApproved ? "Kontot är godkänt" : "Kontot är nekat",
    };
    openConfirmationModal();
  } catch (err) {
    errorMessage.value = GENERIC_ERROR_MSG;
  } finally {
    isLoading.value = false;
  }
};

const handleConfirmationClose = async () => {
  closeConfirmationModal();
  accountHandler.removeAccountApplication(props.account.id);
};

const handleDenialClose = () => {
  closeDenialModal();
  errorMessage.value = "";
  denialModalOpen.value = false;
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-account-application-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include large {
    flex-direction: row;
  }

  & > :not(:last-child) {
    margin-right: rem-calc(8);
  }
}
</style>
