<template>
  <div>
    <div class="st-bo-navigation">
      <div class="st-bo-navigation__item__logo">
        <router-link to="/" class="st-bo-navigation__item__logo__link">
          <img
            :class="logoBySystem"
            :src="imgSrc"
            :alt="`${getOrganisationSystemName()} logo`"
          />
          <span class="st-bo-navigation__item__logo__link__text">
            Företag BackOffice
          </span>
        </router-link>
      </div>
      <button
        v-if="isSmallScreen"
        class="st-bo-navigation__hamburger-menu"
        :tabindex="isSmallScreen ? '1' : '0'"
        @click="openDropdownMenu = !openDropdownMenu"
      >
        <template v-if="openDropdownMenu">
          <close />
          Stäng
        </template>
        <template v-else>
          <hamburger />
          Meny
        </template>
      </button>
      <ul class="st-bo-navigation__items" v-if="!isSmallScreen">
        <li class="st-bo-navigation__item" data-text="Konto">
          <router-link
            class="st-bo-navigation__item__link"
            to="/"
            :class="{
              'active-link': $route.matched.some(
                ({ name }) => name === 'AccountDetails'
              ),
            }"
          >
            Konton
            <st-icon
              v-if="accountApplications > 0"
              name="st-number-notification"
              :icon-props="{ number: accountApplications }"
            />
          </router-link>
        </li>
        <li class="st-bo-navigation__item" data-text="Administratörer">
          <router-link class="st-bo-navigation__item__link" to="/admins">
            Administratörer
          </router-link>
        </li>
        <li class="st-bo-navigation__item" data-text="Resenärer">
          <router-link class="st-bo-navigation__item__link" to="/travellers">
            Resenärer
          </router-link>
        </li>
        <li
          v-if="hasWhitelisting"
          class="st-bo-navigation__item"
          data-text="Domäner"
        >
          <router-link class="st-bo-navigation__item__link" to="/domains">
            Domäner
          </router-link>
        </li>
        <li class="st-bo-navigation__item" @click="logout()">
          <span>Logga ut</span>
        </li>
      </ul>
    </div>
    <div v-if="openDropdownMenu">
      <dropdown-menu
        :close="() => (openDropdownMenu = false)"
        @dropdownMenuClick="handleDropdownMenuSelection"
      />
    </div>
  </div>
</template>
<script>
import { useMsal } from "skanetrafiken-authenticationservice";
import { useHandleWindowWidth } from "@/composables/useHandleWindowWidth";
import Hamburger from "@/components/common/Hamburger";
import { ref, toRefs, getCurrentInstance, computed } from "vue";
import DropdownMenu from "@/components/DropdownMenu";
import Close from "@/components/common/Close";
import { StIcon } from "skanetrafiken";
import { useAccount } from "@/composables/useAccount";
import config from "~/config";

export default {
  name: "st-bo-navigation",
  components: {
    Hamburger,
    DropdownMenu,
    Close,
    StIcon,
  },
  setup() {
    const { instance } = useMsal(getCurrentInstance, toRefs);
    const systemName = process.env.VUE_APP_SYSTEM_APP;
    const { isSmallScreen } = useHandleWindowWidth();
    const openDropdownMenu = ref(false);
    const handleDropdownMenuSelection = () => {
      openDropdownMenu.value = false;
    };

    const account = useAccount();

    const logout = () => {
      instance.logoutRedirect();
    };

    const getOrganisationSystemName = () => {
      return systemName === "st"
        ? "Skånetrafiken"
        : systemName === "bt"
        ? "Blekingetrafiken"
        : "Östgötatrafiken";
    };

    return {
      logout,
      logoBySystem: `logo-${systemName}`,
      imgSrc: `/${systemName}/logo-${
        systemName === "st" ? `${systemName}.jpg` : `${systemName}.png`
      }`,
      isSmallScreen,
      openDropdownMenu,
      handleDropdownMenuSelection,
      accountApplications: computed(
        () => account.state.accountApplications.length
      ),
      getOrganisationSystemName,
      hasWhitelisting: config.APPROVAL_ENABLED,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";

.st-bo-navigation {
  display: flex;
  font-size: rem-calc(20);
  border-bottom: rem-calc(1) solid var(--border-color-tertiary);
  color: $dark-grey;
  justify-content: space-between;
  z-index: 0;

  &__hamburger-menu {
    display: flex;
    flex-direction: column;
    font-size: rem-calc(10);
    align-items: center;
    border: none;
    font-family: inherit;
    padding: rem-calc(19) rem-calc(20);
  }

  &__items {
    display: flex;
    flex-direction: row;
    list-style: none;

    & > :not(:last-child) {
      margin-right: rem-calc(60);
    }

    & > :last-child {
      background: var(--background-color-primary);
      color: var(--text-color-tertiary);
      cursor: pointer;
    }
  }

  &__item {
    display: inline-flex;
    flex-direction: column;
    padding: rem-calc(30) rem-calc(20);
    text-decoration: none;

    &::after {
      content: attr(data-text) / "";
      height: 0;
      visibility: hidden;
      overflow: hidden;
      user-select: none;
      pointer-events: none;
      font-weight: bold;
    }

    &__link {
      display: flex;
      text-decoration: none;
      color: var(--text-color-primary);
      align-items: center;

      & > :first-child {
        margin-left: rem-calc(8);
      }
    }

    &__logo {
      display: flex;
      align-items: center;
      margin-left: rem-calc(10);

      &__link {
        text-decoration: none;

        &__text {
          margin-top: rem-calc(15);
          margin-left: rem-calc(10);
          font-weight: 600;
          font-family: var(--font-secondary);
          color: var(--text-color-primary);
        }

        .logo-st {
          height: rem-calc(30);
        }

        .logo-bt {
          height: rem-calc(48);
        }

        .logo-ot {
          height: rem-calc(48);
        }
      }

      .active-link,
      .active-link:hover {
        font-weight: normal;
        text-decoration: none;
        text-underline-offset: 0;
      }
    }
  }
}

.active-link,
.active-link:hover {
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: rem-calc(8);
  text-decoration-color: var(--background-color-primary);
  text-decoration-thickness: rem-calc(3);
}
</style>
