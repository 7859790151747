import api from "@/axios";
import config from "~/config";

export default {
  GET: {},
  POST: {
    WHITELIST_DOMAIN: async (payload) => {
      try {
        const { data } = await api.post(
          `${config.NEW_ORG_URL}/whitelistings`,
          payload
        );
        return data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    SEARCH_WHITELISTINGS: async (payload) => {
      const { data } = await api.post(
        `${config.NEW_ORG_URL}/whitelistings/search`,
        payload
      );
      return data.items;
    },
  },
  PUT: {},
  DELETE: {
    DELETE_WHITELISTING: async (payload) => {
      try {
        const { data } = await api.delete(
          `${config.NEW_ORG_URL}/whitelistings`,
          {
            data: payload,
          }
        );
        return data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
  },
  PATCH: {},
};
