<template>
  <div class="st-account-list">
    <div v-if="isLoading" class="st-account-list__loader">
      <st-spinner loading-message="Söker konton..." />
    </div>
    <template v-else-if="tableContent.length">
      <span class="st-account-list__result-text">
        Antal konton: {{ tableContent.length }}
      </span>
      <st-table
        :table-data="tableContent"
        :headers="tableHeaders"
        @on-row-click="onRowClick"
        @sort="handleSort"
        clickable
      />
    </template>
    <span v-else-if="hasError" class="st-account-list__error-text">
      {{ search.state.resultError }}
    </span>
    <div v-else-if="hasEmptyList" class="st-account-list__empty-search">
      <EmptyList :is-queue="accountType === ACCOUNT_APPLICATION" />
    </div>
  </div>
</template>
<script setup>
import EmptyList from "./common/EmptyList.vue";
import { useAccountTable } from "@/composables/tables/useAccountTable";
import { useSearch } from "@/composables/useSearch";
import { computed, onBeforeMount, onMounted } from "vue";
import { StTable, StSpinner } from "skanetrafiken";
import { ACCOUNT_APPLICATION } from "@/constants";

const props = defineProps({
  accountType: String,
  onRowClick: Function,
  onMount: Function,
  content: Array,
  hasEmptyList: Boolean,
});

onBeforeMount(() => {
  search.setSearchError(false);
});

onMounted(async () => {
  if (props.onMount) props.onMount();
});

const table = useAccountTable(props.accountType);
const search = useSearch();
const tableHeaders = table.getTableHeaders();

const isLoading = computed(() => search.state.loading);
const hasError = computed(() => search.state.resultError);

const tableContent = computed(() => {
  return table.getTableContent(props.content);
});

const handleSort = (sortBy, order) => {
  table.sortTable(props.content, sortBy, order);
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-account-list {
  display: flex;
  flex-direction: column;
  &__loader {
    padding-top: rem-calc(30);
  }

  &__empty-search {
    margin-top: rem-calc(120);
  }

  &__result-text {
    color: var(--text-color-secondary);
    font-weight: 700;
    padding: rem-calc(8) 0;
  }

  &__error-text {
    padding: rem-calc(16);
    text-align: center;
  }
}
</style>
