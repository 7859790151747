<template>
  <div class="st-bo-status">
    <dot :color="color" />
    <span>{{ title }}</span>
  </div>
</template>
<script>
import Dot from "@/components/common/Dot.vue";

export default {
  name: "st-bo-status",
  components: {
    Dot,
  },
  props: {
    status: Boolean,
    pending: Boolean,
    title: String,
  },
  computed: {
    color() {
      return this.pending ? "#f7bb0d" : this.status ? "#509E2F" : "#cc4039";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";

.st-bo-status {
  padding-right: rem-calc(16);
  & > :not(:last-child) {
    margin-right: rem-calc(5);
  }
}
</style>
