export const useError = () => {
  const getErrorMessage = (errCode) => {
    const errors = {
      BO50100: "Angivet kundnummer kunde inte läsas korrekt",
      BO50200: "Angiven söktyp för resenär är inte giltig",
      BO60100: "Administratör kunde inte hittas",
      BO60200: "En koppling mellan administratör och konto hittades inte",
      BO60210: "Inga administratörer hittades på detta konto",
      BO60220: "Inga konton kunde hittas på administratören",
      BO60230: "Inga adminstratörer kunde hittas på detta konto",
      BO60300: "Kontot kunde inte hittas",
      BO60310: "Inga konton kunde hittas under det organisationsnummret",
      BO61100: "Angivet kortnummer kunde inte hittas",
      BO61200: "Angivet kort är spärrat",
      BO60400: "Resenären kunde inte hittas",
      BO70100: "Administratören är redan inbjuden.",
      BO70200: "Sista administratören på ett konto kan inte tas bort.",
      BO80100:
        "CRM synk misslyckades. Uppdateringen har dock gått igenom i databasen. Vänligen refresha sidan.",
    };
    return errors[errCode];
  };

  return {
    getErrorMessage,
  };
};
