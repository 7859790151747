import { computed, reactive } from "vue";
import axios from "@/axios";
import { useSearch } from "@/composables/useSearch";
import { useTable } from "@/composables/useTable";
import { useAccount } from "@/composables/useAccount";
import {
  ADMIN_ACCOUNTS,
  UPDATE_ADMIN_BLOCKED,
  UPDATE_ADMIN_TOTAL_ACCOUNTS,
  ADMIN,
} from "@/constants";
import config from "~/config";

const state = reactive({
  selectedAdminAccount: {
    accountId: 0,
    displayName: "",
    organizationNumber: "",
  },
  admin: {
    userId: 0,
    ssn: "",
    phoneNumber: "",
    email: "",
    totalAccounts: "",
    givenName: "",
    surname: "",
    blocked: false,
    accounts: [],
    staticPhoneNumber: "", // Både tel. nr och email används som nycklar vid en post, så de gamla värdena måste sparas undan
    staticEmail: "",
  },
});

export const useAdmin = () => {
  const search = useSearch();
  const account = useAccount();
  const table = useTable();

  const getAdminDetails = async () => {
    try {
      const { data } = await axios.get(
        `${config.BASE_URL}/GetDetailedUserInfo?ssn=${state.admin.ssn}&phoneNumber=${state.admin.phoneNumber}&emailAddress=${state.admin.email}`
      );
      updateAdmin();

      state.admin.accounts = data.accounts.map((account) => {
        return {
          tableInfo: { items: table.getTableInfo(ADMIN_ACCOUNTS, account) },
          ...account,
        };
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const setSelectedAdmin = (admin) => {
    state.admin = {
      ...state.admin,
      ...admin,
    };
  };

  const setSelectedAdminAccount = (account) => {
    state.selectedAdminAccount = account;
  };

  const updateAdminInfo = async (newDetails, accountId, fromAccountAdmin) => {
    const payload = fromAccountAdmin
      ? newDetails
      : {
          ssn: state.admin.ssn,
          phoneNumber: state.admin.staticPhoneNumber,
          emailAddress: state.admin.staticEmail,
          newPhoneNumber: newDetails.phoneNumber,
          newEmailAddress: newDetails.email,
        };
    const fromAccountAdminUpdate = `UpdateAccountUser?userId=${state.admin.userId}&accountId=${accountId}`;
    const adminSearchUpdate = `UpdateUserContactInfo`;
    try {
      await axios.put(
        `${config.BASE_URL}/${
          fromAccountAdmin ? fromAccountAdminUpdate : adminSearchUpdate
        }`,
        payload
      );

      state.admin = {
        ...state.admin,
        phoneNumber: newDetails.phoneNumber,
        email: newDetails.email,
      };
      const updateValues = ["phoneNumber", "email"];

      table.updateTableInfo(state.admin.tableInfo, state.admin, updateValues);
      await getAdminDetails();

      if (fromAccountAdmin) {
        account.updateAccountAdmin(state.admin);
      } else {
        //Vid uppdatering av admins uppgifter behöver sökresultatet uppdateras då resultatraderna kan behövas omarrangeras(nytt antal, merga ihop rader osv)
        await search.updateSearchResult(ADMIN);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const blockAdmin = async (adminType) => {
    const blocked = !state.admin.blocked;
    const payload = {
      ssn: state.admin.ssn,
      status: blocked,
    };
    try {
      await axios.put(`${config.NEW_ORG_URL}/UpdateUserBlockedStatus`, payload);

      state.admin = { ...state.admin, blocked: blocked };

      const updateValues = ["blocked"];
      const text = state.admin.blocked ? "Spärrad" : "Ja";

      table.updateTableInfo(
        state.admin.tableInfo.items,
        state.admin,
        updateValues,
        text
      );

      if (adminType === ADMIN_ACCOUNTS) {
        account.updateAccountAdmin(state.admin);
      } else {
        search.updateAdminSearchResult(state.admin, UPDATE_ADMIN_BLOCKED);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const addAdmin = async (email, accountId) => {
    const payload = { accountId, email };
    try {
      await axios.post(`${config.BASE_URL}/CreateInvitation`, payload);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const updateAdmin = () => {
    state.admin = {
      ...state.admin,
      staticPhoneNumber: state.admin.phoneNumber,
      staticEmail: state.admin.email,
    };
  };

  const removeAdminFromAccount = async (accountId, userId, adminType) => {
    const payload = {
      accountId,
      userId,
    };
    try {
      await axios.delete(`${config.BASE_URL}/DeleteAccountUser`, {
        data: payload,
      });

      state.admin = {
        ...state.admin,
        totalAccounts: state.admin.totalAccounts - 1,
        accounts: state.admin.accounts.filter(
          (account) => account.accountId !== accountId
        ),
      };

      const updateValues = ["totalAccounts"];

      table.updateTableInfo(state.admin.tableInfo, state.admin, updateValues);

      if (adminType === ADMIN_ACCOUNTS) {
        //Har det borttagna kontot blivit borttaget via detta kontot så ska det bort från listan
        account.isSelectedAccount(accountId)
          ? account.removeAccountAdmin(userId)
          : account.updateAccountAdmin(state.admin);
      } else {
        search.updateAdminSearchResult(
          state.admin,
          UPDATE_ADMIN_TOTAL_ACCOUNTS
        );
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleAdminBlockText = computed(() => {
    const isBlocked = state.admin.blocked;
    return `Är du säker på att du vill 
    ${isBlocked ? "aktivera" : "spärra"} 
    personnummer 
    ${state.admin.ssn}? 
    Detta påverkar alla administratörskonton kopplade till personnumret.`;
  });

  return {
    state,
    addAdmin,
    getAdminDetails,
    setSelectedAdmin,
    updateAdminInfo,
    blockAdmin,
    removeAdminFromAccount,
    setSelectedAdminAccount,
    handleAdminBlockText,
  };
};
