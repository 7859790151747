<template>
  <StModal size="medium" :on-close="() => $emit(CLOSE)">
    <div class="st-bo-svea-key-modal">
      <div class="st-bo-svea-key-modal__title">
        <StHeadline :level="2">SVEA Nyckel</StHeadline>
      </div>
      <div class="st-bo-svea-key-modal__input">
        <st-form-group label="SVEA Nyckel">
          <st-input
            id="svea-key-modal-input-key"
            type="text"
            :model-value="account.invoiceInfo.sveaPublicKey"
            @input="(value) => handleInput('sveaPublicKey', value)"
            tabindex="0"
            required
            :invalid="false"
          />
        </st-form-group>
      </div>
      <div class="st-bo-svea-key-modal__buttons">
        <st-button full-width @click="$emit(CLOSE)" tabindex="0">
          Avbryt
        </st-button>
        <st-button
          primary
          full-width
          @click="$emit('update', newSveaPublicKey)"
          tabindex="0"
          :is-loading="isLoading"
        >
          Uppdatera
        </st-button>
      </div>
    </div>
  </StModal>
</template>

<script>
import {
  StModal,
  StButton,
  StInput,
  StFormGroup,
  StHeadline,
} from "skanetrafiken";
import { computed, ref } from "vue";
import { useAccount } from "@/composables/useAccount";
import { CLOSE } from "@/constants";
export default {
  name: "st-bo-svea-key-modal",
  components: {
    StModal,
    StButton,
    StInput,
    StFormGroup,
    StHeadline,
  },
  props: {
    isLoading: Boolean,
  },
  setup() {
    const account = useAccount();
    const newSveaPublicKey = ref("");

    const handleInput = (key, e) => {
      newSveaPublicKey.value = e.target.value;
    };

    return {
      account: computed(() => account.state.account),
      handleInput,
      newSveaPublicKey,
      CLOSE,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-svea-key-modal {
  &__title {
    text-align: center;
  }

  &__input {
    text-align: left;
    margin: rem-calc(30) 0;
  }

  &__buttons {
    display: flex;
    flex-direction: row;

    & > :last-child {
      margin-left: rem-calc(10);
    }
  }
}
</style>
