<template>
  <div class="st-bo-account-details">
    <div class="st-bo-account-details__header">
      <div class="st-bo-account-details__header__title">
        <StHeadline :level="2">Kontodetaljer</StHeadline>
      </div>
      <div class="st-bo-account-details__header__status">
        <status
          :status="!account.blocked"
          :title="account.blocked ? 'Spärrat' : 'Aktivt Konto'"
        />
        <status
          :status="account.isCreditWorthy"
          :title="
            account.isCreditWorthy
              ? 'Konto är aktiverat för köp'
              : 'Konto är inaktiverat för köp'
          "
        />
      </div>
    </div>
    <div class="st-bo-account-details__wrapper">
      <div class="st-bo-account-details__column__inputs">
        <st-form-group
          class="st-bo-account-details__column__inputs__editable"
          label="Kontonamn"
        >
          <st-input
            v-model="account.accountName"
            disabled
            id="account-details-input-accName"
            tabindex="0"
          />
        </st-form-group>
        <st-form-group
          class="st-bo-account-details__column__inputs__editable"
          label="UG Nummer"
        >
          <st-input
            v-model="account.ugNumber"
            disabled
            id="account-details-input-ugNbr"
            tabindex="0"
          />
        </st-form-group>
        <st-input
          v-model="account.accountId"
          disabled
          :label-name="'Kundnummer'"
          id="account-details-input-accNbr"
          tabindex="0"
        />
      </div>
      <div class="st-bo-account-details__column__btns">
        <st-button
          class="st-bo-account-details__column__btns__btn"
          tabindex="0"
          @click="openAccountNameModal"
          :disabled="isDisabled"
        >
          <span v-if="isSmallScreen">Ändra kontonamn</span>
          <edit class="st-bo-account-details__column__btns__btn__icon" />
        </st-button>
        <st-button
          class="st-bo-account-details__column__btns__btn"
          tabindex="0"
          @click="openUgNumberModal"
          :disabled="isDisabled"
        >
          <span v-if="isSmallScreen">Ändra UG Nummer</span>
          <edit class="st-bo-account-details__column__btns__btn__icon" />
        </st-button>
      </div>
      <div class="st-bo-account-details__column__inputs">
        <st-input
          v-model="account.organizationName"
          disabled
          :label-name="'Organisationsnamn'"
          id="account-details-input-orgName"
          tabindex="0"
        />
        <st-input
          v-model="account.organizationNumber"
          disabled
          :label-name="'Organisationsnummer'"
          id="account-details-input-orgNbr"
          tabindex="0"
          :invalid="false"
        />
      </div>
    </div>
    <div class="st-bo-account-details__buttons">
      <st-button
        tabindex="0"
        @click="openPurchaseStatusModal"
        :disabled="isDisabled"
      >
        {{
          account.isCreditWorthy ? "Inaktivera för köp " : "Aktivera för köp"
        }}
      </st-button>
      <st-button
        tabindex="0"
        @click="openBlockAccountModal"
        :disabled="isDisabled"
      >
        {{ account.blocked ? "Aktivera konto" : "Spärra konto" }}
      </st-button>
      <st-button
        tabindex="0"
        @click="updateOrgName"
        :disabled="isDisabled"
        :is-loading="isLoading.orgName"
      >
        Synka org. namn
      </st-button>

      <st-information-box
        class="st-bo-account-details__buttons__feedback"
        v-if="actionBarFeedback.show"
        :information-type="actionBarFeedback.success ? 'success' : 'failure'"
        small
      >
        {{ actionBarFeedback.text }}
      </st-information-box>
    </div>
    <block-account-modal
      ref="blockAccountModal"
      @close="closeBlockAccountModal"
      @open-confirm-modal="openConfirmBlockModal"
      :account-name="account.accountName"
      :organization-number="account.organizationNumber"
      :is-blocked="account.blocked"
    />
    <confirm-block-account-modal
      ref="confirmBlockAccountModal"
      @close="closeConfirmBlockAccountModal"
      @block="updateAccountBlock"
      :account-name="account.accountName"
      :block-on-org-number="blockAllAccountsWithOrgnr"
      :organization-number="account.organizationNumber"
      :is-blocked="account.blocked"
      :is-loading="isLoading.block"
    />
    <handle-purchase-status-modal
      ref="purchaseStatusModal"
      @close="closePurchaseStatusModal"
      @change="updatePurchaseStatus"
      :purchase-allowed="account.isCreditWorthy"
      :is-loading="isLoading.purchaseStatus"
    />
    <account-name-modal
      ref="accountNameModal"
      :is-loading="isLoading.accountName"
      @updateAccountName="updateAccountName"
      @close="closeAccountNameModal"
    />
    <ug-number-modal
      ref="ugNumberModal"
      :is-loading="isLoading.ugNumber"
      @updateUgNumber="updateUgNumber"
      @close="closeUgNumberModal"
    />
    <confirmation-modal
      ref="confirmationModal"
      :title="confirmationModalInfo.title"
      :error="confirmationModalInfo.error"
      :status="confirmationModalInfo.status"
      @close="closeConfirmationModal"
    />
  </div>
</template>
<script>
import BlockAccountModal from "@/components/modals/BlockAccountModal.vue";
import ConfirmBlockAccountModal from "@/components/modals/ConfirmBlockAccountModal.vue";
import HandlePurchaseStatusModal from "@/components/modals/HandlePurchaseStatusModal.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import AccountNameModal from "@/components/modals/AccountNameModal.vue";
import UgNumberModal from "@/components/modals/UgNumberModal.vue";
import Status from "@/components/common/Status.vue";
import {
  StInput,
  StButton,
  StFormGroup,
  StInformationBox,
  StHeadline,
} from "skanetrafiken";
import Edit from "@/components/common/Edit.vue";
import { useAccount } from "@/composables/useAccount";
import { computed, reactive, ref } from "vue";
import { useHandleWindowWidth } from "@/composables/useHandleWindowWidth";
import { useError } from "@/composables/useError";
import { useModal } from "@/composables/useModal";
import { GENERIC_ERROR_MSG } from "@/constants";

export default {
  name: "st-bo-account-details",
  props: {
    isDisabled: Boolean,
  },
  components: {
    StButton,
    StInput,
    StHeadline,
    BlockAccountModal,
    ConfirmBlockAccountModal,
    HandlePurchaseStatusModal,
    AccountNameModal,
    UgNumberModal,
    ConfirmationModal,
    Status,
    StInformationBox,
    StFormGroup,
    Edit,
  },
  setup() {
    const account = useAccount();
    const { isSmallScreen } = useHandleWindowWidth();
    const errorMessages = useError();
    const editDetails = ref(false);
    const actionBarFeedback = ref({ show: false, text: "", success: false });

    const blockAllAccountsWithOrgnr = ref(false);

    const confirmationModalInfo = ref({
      title: "",
      status: false,
      error: {},
    });

    const isLoading = reactive({
      accountName: false,
      ugNumber: false,
      orgName: false,
      block: false,
      purchaseStatus: false,
    });

    const accountNameModal = ref(null);
    const ugNumberModal = ref(null);
    const confirmationModal = ref(null);
    const confirmBlockAccountModal = ref(null);
    const blockAccountModal = ref(null);
    const purchaseStatusModal = ref(null);

    const [openAccountNameModal, closeAccountNameModal] =
      useModal(accountNameModal);
    const [openUgNumberModal, closeUgNumberModal] = useModal(ugNumberModal);
    const [openConfirmationModal, closeConfirmationModal] =
      useModal(confirmationModal);
    const [openBlockAccountModal, closeBlockAccountModal] =
      useModal(blockAccountModal);

    const [openPurchaseStatusModal, closePurchaseStatusModal] =
      useModal(purchaseStatusModal);

    const [openConfirmBlockAccountModal, closeConfirmBlockAccountModal] =
      useModal(confirmBlockAccountModal);

    const openConfirmBlockModal = (checked) => {
      closeBlockAccountModal();
      blockAllAccountsWithOrgnr.value = checked;
      openConfirmBlockAccountModal();
    };

    const updateAccountBlock = async (blockOnOrgNumber) => {
      try {
        isLoading.block = true;
        await account.updateAccountBlock(blockOnOrgNumber);
        isLoading.block = false;
        closeConfirmBlockAccountModal();

        const blocked = account.state.account.blocked;
        const accountName = account.state.account.accountName || "Kontot";
        const title = blockOnOrgNumber
          ? `Samtliga konton under ${
              account.state.account.organizationNumber
            } är nu
            ${blocked ? "spärrade" : "aktiverade"}.`
          : `${accountName} är nu ${blocked ? "spärrat" : "aktiverat"}.`;

        blockAllAccountsWithOrgnr.value = false;
        showConfirmation(title);
      } catch (err) {
        isLoading.block = false;
        closeConfirmBlockAccountModal();
        showConfirmation(GENERIC_ERROR_MSG, err.response.data || err);
      }
    };

    const updateAccountName = async (newAccountName) => {
      try {
        isLoading.accountName = true;
        await account.updateAccountDetails(
          newAccountName,
          account.state.account.ugNumber
        );
        isLoading.accountName = false;
        closeAccountNameModal();
        showConfirmation("Kontonamn uppdaterat.");
      } catch (err) {
        isLoading.accountName = false;
        closeAccountNameModal();
        showConfirmation(GENERIC_ERROR_MSG, err.response.data || err);
      }
    };

    const updateUgNumber = async (newUgNumber) => {
      try {
        isLoading.ugNumber = true;
        await account.updateAccountDetails(
          account.state.account.accountName,
          newUgNumber
        );
        isLoading.ugNumber = false;
        closeUgNumberModal();
        showConfirmation("UG Nummer uppdaterat.");
      } catch (err) {
        isLoading.ugNumber = false;
        closeUgNumberModal();
        showConfirmation(GENERIC_ERROR_MSG, err.response.data || err);
      }
    };

    const updatePurchaseStatus = async () => {
      try {
        isLoading.purchaseStatus = true;
        await account.updatePurchaseStatus();
        isLoading.purchaseStatus = false;
        closePurchaseStatusModal();
        const accountName = account.state.account.accountName || "Kontot";
        const title = `${accountName} är nu ${
          account.state.account.isCreditWorthy ? "aktiverat" : "inaktiverat"
        } för köp.`;
        showConfirmation(title);
      } catch (err) {
        isLoading.purchaseStatus = false;
        closePurchaseStatusModal();
        showConfirmation(GENERIC_ERROR_MSG, err.response.data || err);
      }
    };

    const updateOrgName = async () => {
      actionBarFeedback.value.success = false;
      actionBarFeedback.value.show = false;
      try {
        isLoading.orgName = true;
        await account.getAccountOrgName();
        isLoading.orgName = false;
        actionBarFeedback.value.success = true;
        showActionBarFeedback("Organisationsnamn hämtat");
      } catch (err) {
        isLoading.orgName = false;
        showActionBarFeedback(
          errorMessages.getErrorMessage(err.response.data.code || err.code) ||
            GENERIC_ERROR_MSG
        );
      }
    };

    const showActionBarFeedback = (text) => {
      actionBarFeedback.value = {
        show: true,
        text,
        success: actionBarFeedback.value.success,
      };
      if (actionBarFeedback.value.success) {
        setTimeout(() => {
          actionBarFeedback.value.show = false;
        }, 3000);
      }
    };

    const showConfirmation = (title, error) => {
      confirmationModalInfo.value = {
        title,
        status: !error,
        error: error ? error : {},
      };
      openConfirmationModal();
    };

    return {
      account: computed(() => account.state.account),
      editDetails,
      updateUgNumber,
      confirmationModalInfo,
      updatePurchaseStatus,
      openConfirmBlockModal,
      updateAccountBlock,
      updateOrgName,
      actionBarFeedback,
      updateAccountName,
      isSmallScreen,
      isLoading: computed(() => isLoading),
      accountNameModal,
      ugNumberModal,
      blockAccountModal,
      confirmBlockAccountModal,
      confirmationModal,
      purchaseStatusModal,
      closeAccountNameModal,
      closeConfirmationModal,
      closeUgNumberModal,
      closeBlockAccountModal,
      closeConfirmBlockAccountModal,
      closePurchaseStatusModal,
      openAccountNameModal,
      openUgNumberModal,
      openBlockAccountModal,
      openConfirmBlockAccountModal,
      openPurchaseStatusModal,
      blockAllAccountsWithOrgnr,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";

.st-bo-account-details {
  display: flex;
  flex-direction: column;
  text-align: start;
  padding-bottom: rem-calc(16);

  @include large {
    width: 70vw;
  }

  &__header {
    display: flex;
    margin-bottom: rem-calc(15);
    flex-direction: column;

    @include large {
      flex-direction: row;
      align-items: center;
    }

    &__title {
      font-size: rem-calc(25);
      margin-right: rem-calc(15);
    }

    &__status {
      display: flex;
      flex-direction: column;

      @include large {
        flex-direction: row;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;

    & > * {
      line-height: rem-calc(18);
    }

    & > :not(:last-child) {
      margin-bottom: rem-calc(15);
    }

    @include large {
      flex-direction: row;

      & > :not(:last-child) {
        margin-right: rem-calc(15);
        margin-bottom: 0;
      }
    }

    &__feedback {
      padding: rem-calc(8) rem-calc(12);
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    @include large {
      flex-direction: row;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;

    &__inputs {
      flex-grow: 8;

      @include large {
        margin-right: rem-calc(8);
      }

      & > * {
        margin-bottom: rem-calc(8);
        max-height: rem-calc(70);
      }

      &__editable {
        flex-grow: 1;
        margin-top: 0;
      }
    }

    &__btns {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      & > :not(:last-child) {
        margin-bottom: rem-calc(8);
      }

      @include large {
        margin-top: rem-calc(21);

        & > :not(:last-child) {
          margin-bottom: rem-calc(30);
        }
      }

      @include large {
        margin-right: rem-calc(16);
      }

      &__btn {
        display: inline-flex;
        padding: 0 rem-calc(8);
        max-width: 100vh;

        @include large {
          max-width: rem-calc(48);
        }

        &__icon {
          margin: 0 !important;
        }
      }
    }
  }
}
</style>
