import { createApp, reactive } from "vue";
import { EventType, msalPlugin } from "skanetrafiken-authenticationservice";
import { instance } from "./azure-ad";
import App from "./App.vue";
import router from "./router";

const plugin = msalPlugin(reactive);

const accounts = instance.getAllAccounts();
if (accounts.length > 0) {
  instance.setActiveAccount(accounts[0]);
}
instance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload;
    const account = payload.account;
    instance.setActiveAccount(account);
  }
});

const app = createApp(App).use(router).use(plugin, instance);

router.isReady().then(() => {
  // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
  app.mount("#app");
});
