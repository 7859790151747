<template>
  <div class="st-quick-search">
    <label class="st-quick-search__label" v-if="labelName" for="quick-search">
      {{ labelName }}
    </label>
    <div class="st-quick-search__container">
      <div class="st-quick-search__container__input">
        <input
          id="quick-search"
          class="st-quick-search__container__input__input-field"
          type="text"
          :value="modelValue"
          autocomplete="off"
          @input="emit('update:modelValue', $event.target.value)"
          @keyup.esc="emit('update:modelValue', '')"
          @keydown.enter="validSearch && emit('search')"
        />
        <button
          class="st-quick-search__container__input__search-button"
          @click="emit('search')"
          :disabled="!validSearch"
        >
          <st-icon name="st-search" :icon-props="{ color: 'white' }" />
          <span class="st-quick-search__container__input__search-button__text">
            Sök
          </span>
        </button>
      </div>
      <div v-if="showAll" class="st-quick-search__container__show-all">
        <StButton @click="emit('search', true)">Visa alla</StButton>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { StIcon, StButton } from "skanetrafiken";

const emit = defineEmits(["search", "update:modelValue"]);

const props = defineProps({
  modelValue: String,
  labelName: String,
  showAll: Boolean,
});

const validSearch = computed(() => {
  return props.modelValue.trim().length;
});
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-quick-search {
  &__label {
    color: var(--text-color-primary);
    display: flex;
    margin-bottom: rem-calc(8);
  }

  &__container {
    display: flex;
    flex-direction: column;
    @include large {
      flex-direction: row;
    }

    &__input {
      display: flex;
      flex-direction: row;
      flex-grow: 1;

      &__input-field {
        height: rem-calc(48);
        width: 100%;
        padding-left: $lg;
        color: var(--text-color-primary);
        font-size: rem-calc(16);
        border: var(--border-width--thin) solid var(--border-color-quaternary);
        border-right: none;
        border-radius: $border-radius 0 0 $border-radius;

        &:focus {
          box-shadow: 0 0 0 rem-calc(2) var(--background-color-secondary),
            0 0 0 rem-calc(4) var(--border-color-quaternary);
          border-radius: rem-calc(6);
          outline: none;
        }

        &:disabled {
          background-color: var(--container-color-secondary);
        }
      }

      &__search-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 rem-calc(18);
        font-family: inherit;
        font-size: rem-calc(16);
        color: var(--text-color-tertiary);
        background-color: var(--background-color-primary);
        border-radius: 0 $border-radius $border-radius 0;

        &:focus {
          box-shadow: 0 0 0 rem-calc(2) var(--background-color-secondary),
            0 0 0 rem-calc(4) var(--border-color-quaternary);
          border-radius: rem-calc(6);
          outline: none;
        }

        &:disabled {
          background-color: var(--disabled-color-primary);
        }

        &__text {
          margin-left: rem-calc(8);
        }
      }
    }

    &__show-all {
      min-width: rem-calc(150);
      margin-top: rem-calc(16);
      @include large {
        margin-left: rem-calc(16);
        margin-top: 0;
      }
    }
  }
}
</style>
