<template>
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36 66C19.431 66 6 52.569 6 36C6 19.431 19.431 6 36 6C52.569 6 66 19.431 66 36C66 52.569 52.569 66 36 66ZM33.009 48L54.219 26.787L49.977 22.545L33.009 39.516L24.522 31.029L20.28 35.271L33.009 48Z"
      fill="#509E2F"
    />
  </svg>
</template>
