import { ref } from "vue";

const whitelistings = ref([]);

export const useDomain = () => {
  const removeWhitelisting = (idToDelete) => {
    whitelistings.value = whitelistings.value.filter(
      (domain) => domain.id !== idToDelete
    );
  };

  const setWhitelistings = (domains) => {
    whitelistings.value = domains;
  };

  const clearWhitelistings = () => {
    whitelistings.value = [];
  };

  return {
    whitelistings,
    clearWhitelistings,
    removeWhitelisting,
    setWhitelistings,
  };
};
