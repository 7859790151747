<template>
  <StModal :on-close="() => $emit(CLOSE)">
    <div class="st-bo-handle-purchase-modal">
      <StHeadline :level="2"> {{ actionTitle }} för köp</StHeadline>
      <p class="st-bo-handle-purchase-modal__paragraph">
        Är du säker på att du vill {{ actionTitle.toLowerCase() }} detta konto
        för köp?
      </p>
      <div class="st-bo-handle-purchase-modal__buttons">
        <st-button full-width @click="$emit(CLOSE)" tabindex="0">
          Avbryt
        </st-button>
        <st-button
          primary
          full-width
          @click="$emit('change')"
          tabindex="0"
          :is-loading="isLoading"
        >
          {{ actionTitle }}
        </st-button>
      </div>
    </div>
  </StModal>
</template>

<script>
import { StModal, StButton, StHeadline } from "skanetrafiken";
import { computed } from "vue";
import { CLOSE } from "@/constants";
export default {
  name: "st-bo-confirm-block-account-modal",
  components: {
    StModal,
    StButton,
    StHeadline,
  },
  props: {
    purchaseAllowed: Boolean,
    isLoading: Boolean,
  },
  setup(props) {
    return {
      actionTitle: computed(() =>
        props.purchaseAllowed ? "Inaktivera" : "Aktivera"
      ),
      CLOSE,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-handle-purchase-modal {
  text-align: center;

  &__paragraph {
    margin: rem-calc(30) 0;
  }

  &__buttons {
    display: flex;
    flex-direction: row;

    & > :last-child {
      margin-left: rem-calc(10);
    }
  }
}
</style>
