import Account from "./Account";
import Admin from "./Admin";
import Domain from "./Domain";

export default {
  Account: {
    GET: Account.GET,
    POST: Account.POST,
    PUT: Account.PUT,
    DELETE: Account.DELETE,
    PATCH: Account.PATCH,
  },
  Admin: {
    GET: Admin.GET,
    POST: Admin.POST,
    PUT: Admin.PUT,
    DELETE: Admin.DELETE,
    PATCH: Admin.PATCH,
  },
  Domain: {
    GET: Domain.GET,
    POST: Domain.POST,
    PUT: Domain.PUT,
    DELETE: Domain.DELETE,
    PATCH: Domain.PATCH,
  },
};
