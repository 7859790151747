<template>
  <div class="st-bo-account-billing-information">
    <div class="st-bo-account-billing-information__header">
      <StHeadline :level="4"> Faktureringsuppgifter </StHeadline>
      <status
        v-if="hasAddressPending()"
        title="Senaste adressändringen är just nu under granskning"
        pending
      />
    </div>
    <div class="st-bo-account-billing-information__addresses">
      <div>
        <address-information
          title="Fakturaadress"
          :address="account.invoiceInfo"
        />
        <st-button
          class="st-bo-account-billing-information__addresses__btn"
          :disabled="isDisabled || hasAddressPending()"
          tabindex="0"
          @click="openChangeAddressModal"
        >
          Byt fakturaadress
        </st-button>
      </div>
      <address-information
        v-if="hasAddressPending()"
        title="Under granskning"
        :address="account.pendingInvoiceInfo"
      />
    </div>
    <div class="st-bo-account-billing-information__inputs">
      <st-form-group
        label="Fakturareferens"
        required
        :validation="v$.reference"
        :validation-errors="validationErrors.reference"
      >
        <st-input
          id="account-billing-information-input-ref"
          :model-value="billingInformation.reference"
          tabindex="0"
          @input="(value) => handleInput('reference', value)"
          :blur="() => handleBlur('reference')"
          :invalid="v$ && v$.reference && v$.reference.$error"
          required
          :disabled="!editBilling"
        />
      </st-form-group>
      <label class="st-bo-account-billing-information__inputs__label">
        Faktura vald som:
      </label>
      <st-select
        :items="options"
        @change="(value) => handleInput('distributionType', value)"
        :model-value="selectedOption"
        :hasEmpty="false"
        :disabled="!editBilling"
      />

      <div class="st-bo-account-billing-information__inputs__optional">
        <st-form-group
          v-if="mailSelected"
          label="Mejladress"
          required
          :validation="v$.invoiceEmail"
          :validation-errors="validationErrors.invoiceEmail"
        >
          <st-input
            id="account-billing-information-input-mail"
            :model-value="billingInformation.invoiceEmail"
            tabindex="0"
            @input="(value) => handleInput('invoiceEmail', value)"
            :blur="() => handleBlur('invoiceEmail')"
            :invalid="v$ && v$.invoiceEmail && v$.invoiceEmail.$error"
            required
            :disabled="!editBilling"
          />
        </st-form-group>
        <st-form-group
          v-else-if="peppolSelected"
          label="Peppol ID"
          required
          :validation="v$.peppolId"
          :validation-errors="validationErrors.peppolId"
        >
          <st-input
            id="account-billing-information-input-peppol"
            :model-value="billingInformation.peppolId"
            tabindex="0"
            @input="(value) => handleInput('peppolId', value)"
            :blur="() => handleBlur('peppolId')"
            :invalid="v$ && v$.peppolId && v$.peppolId.$error"
            required
            :disabled="!editBilling"
          />
        </st-form-group>
      </div>
    </div>
    <div class="st-bo-account-billing-information__buttons">
      <st-button
        primary
        @click="handleUpdate"
        tabindex="0"
        :is-loading="isLoading.billingInfo"
        :disabled="isDisabled"
      >
        {{ editBilling ? "Spara ändringar" : "Ändra uppgifter" }}
      </st-button>
    </div>
    <div class="st-bo-account-billing-information__inputs__svea">
      <div class="st-bo-account-billing-information__inputs__svea__input">
        <st-input
          id="account-billing-information-input-key"
          v-model="account.invoiceInfo.sveaPublicKey"
          :label-name="'SVEA nyckel'"
          tabindex="0"
          :invalid="false"
          :required="false"
          disabled
        />
        <st-button
          class="st-bo-account-billing-information__inputs__svea__input__button"
          @click="openSveaModal"
          :disabled="isDisabled || hasAddressPending()"
          tabindex="0"
        >
          Ändra SVEA nyckel
        </st-button>
      </div>
      <span class="st-bo-account-billing-information__inputs__svea__text">
        Viktigt! En ändring av en svea-nyckel påverkar samtliga konton under
        denna address.
      </span>
    </div>
  </div>
  <change-billing-address-modal
    ref="changeAddressModal"
    @updateAddress="handleAddressUpdate"
    @close="closeChangeAddressModal"
    :is-loading="isLoading.address"
  />
  <svea-key-modal
    ref="sveaModal"
    @update="handleSveaKeyUpdate"
    @close="closeSveaModal"
    :is-loading="isLoading.sveaKey"
  />
  <confirmation-modal
    ref="confirmationModal"
    :title="showConfirmationModal.title"
    :status="showConfirmationModal.status"
    :error="showConfirmationModal.error"
    @close="closeConfirmationModal"
  />
</template>

<script>
import {
  StInput,
  StButton,
  StFormGroup,
  StSelect,
  StHeadline,
} from "skanetrafiken";
import ChangeBillingAddressModal from "@/components/modals/ChangeBillingAddressModal.vue";
import SveaKeyModal from "@/components/modals/SveaKeyModal.vue";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import Status from "@/components/common/Status.vue";
import AddressInformation from "@/components/common/AddressInformation.vue";
import { useAccount } from "@/composables/useAccount";
import { computed, ref, onMounted, reactive } from "vue";
import ChangeBillingInformationValidations from "@/validators/change-billing-information-validations";
import ChangeBillingInformationValidationsErrors from "@/validators/change-billing-information-validations-errors";
import { useVuelidate } from "@vuelidate/core";
import { GENERIC_ERROR_MSG } from "@/constants";
import { useModal } from "@/composables/useModal";

export default {
  name: "st-bo-account-billing-information",
  props: {
    isDisabled: Boolean,
  },
  components: {
    StInput,
    StButton,
    StSelect,
    ChangeBillingAddressModal,
    SveaKeyModal,
    ConfirmationModal,
    Status,
    AddressInformation,
    StFormGroup,
    StHeadline,
  },
  setup() {
    const account = useAccount();
    const editBilling = ref(false);
    const showSveaKeyModal = ref(false);
    const showConfirmationModal = ref({
      title: "",
      status: false,
      error: {},
    });

    const isLoading = reactive({
      billingInfo: false,
      address: false,
      sveaKey: false,
    });

    const options = [
      { name: "Pappersfaktura", id: 1 },
      { name: "Mejladress", id: 2 },
      { name: "Peppol", id: 4 },
    ];
    const billingInformation = ref({
      reference: "",
      invoiceEmail: "",
      peppolId: "",
      distributionType: 0,
    });

    const changeAddressModal = ref(null);
    const confirmationModal = ref(null);
    const sveaModal = ref(null);

    const [openChangeAddressModal, closeChangeAddressModal] =
      useModal(changeAddressModal);

    const [openConfirmationModal, closeConfirmationModal] =
      useModal(confirmationModal);

    const [openSveaModal, closeSveaModal] = useModal(sveaModal);

    const v$ = useVuelidate(
      ChangeBillingInformationValidations,
      billingInformation
    );

    const handleInput = (key, e) => {
      if (key === "distributionType") {
        billingInformation.value[key] = Number(e.target.value);
      } else {
        billingInformation.value[key] = e.target.value;
      }
    };

    const handleSveaKeyUpdate = async (newSveaPublicKey) => {
      try {
        isLoading.sveaKey = true;
        await account.updateSveaKey(newSveaPublicKey);
        isLoading.sveaKey = false;
        closeSveaModal();
        showConfirmation("SVEA Nyckeln är nu uppdaterad.");
      } catch (err) {
        isLoading.sveaKey = false;
        closeSveaModal();
        showConfirmation(GENERIC_ERROR_MSG, err.response.data || err);
      }
    };

    const hasAddressPending = () => {
      return !!account.state.account.pendingInvoiceInfo;
    };

    const handleAddressUpdate = async (address, applied) => {
      const isNewAddress = applied ? true : false;
      try {
        isLoading.address = true;
        await account.updateAddress(address, isNewAddress);
        isLoading.address = false;
        closeChangeAddressModal();
        const text = applied
          ? "Ansökan om adress ändring har nu skickats till SVEA"
          : "Adressen är nu uppdaterad";
        showConfirmation(text);
      } catch (err) {
        isLoading.address = false;
        closeChangeAddressModal();
        showConfirmation(GENERIC_ERROR_MSG, err.response.data || err);
      }
    };

    const handleUpdate = async () => {
      if (editBilling.value) {
        v$.value.$touch();
        const errors = v$.value.$errors;
        if (errors.length) return;
        try {
          isLoading.billingInfo = true;
          await account.updateInvoiceInfo(billingInformation.value);
          showConfirmation("Dina faktureringsuppgifter har nu uppdaterats.");
          isLoading.billingInfo = false;
          editBilling.value = false;
        } catch (err) {
          isLoading.billingInfo = false;
          editBilling.value = false;
          showConfirmation(GENERIC_ERROR_MSG, err.response.data || err);
        }
      } else {
        editBilling.value = true;
      }
    };

    const showConfirmation = (title, error) => {
      showConfirmationModal.value = {
        title,
        status: !error,
        error: error ? error : {},
      };
      openConfirmationModal();
    };

    const handleBlur = (prop) => {
      v$.value[prop].$touch();
    };

    onMounted(() => {
      billingInformation.value = {
        reference: account.state.account.reference,
        invoiceEmail: account.state.account.invoiceEmail,
        peppolId: account.state.account.peppolId,
        distributionType: account.state.account.distributionType,
      };
    });

    return {
      account: computed(() => account.state.account),
      handleAddressUpdate,
      handleInput,
      handleUpdate,
      options,
      selectedOption: computed(() => billingInformation.value.distributionType),
      showSveaKeyModal,
      showConfirmationModal,
      handleSveaKeyUpdate,
      hasAddressPending,
      peppolSelected: computed(
        () => billingInformation.value.distributionType == 4
      ),
      mailSelected: computed(
        () => billingInformation.value.distributionType == 2
      ),
      editBilling,
      v$,
      handleBlur,
      validationErrors: ChangeBillingInformationValidationsErrors,
      billingInformation,
      isLoading: computed(() => isLoading),
      changeAddressModal,
      closeChangeAddressModal,
      openChangeAddressModal,
      confirmationModal,
      openConfirmationModal,
      closeConfirmationModal,
      sveaModal,
      openSveaModal,
      closeSveaModal,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-account-billing-information {
  border: rem-calc(1) solid $dark-grey;
  border-radius: rem-calc(6);
  padding: rem-calc(16);
  text-align: left;
  font-family: $source-sans-pro;

  @include large {
    width: 70vw;
  }

  & > * {
    margin-bottom: rem-calc(16);
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include large {
      flex-direction: row;
    }

    & > :not(:last-child) {
      margin-right: rem-calc(32);
    }
  }

  &__addresses {
    display: flex;
    flex-direction: column;
    border-radius: rem-calc(6);
    background-color: #f5f3f0;
    padding: rem-calc(8);

    @include large {
      flex-direction: row;
    }

    & > :first-child {
      margin-bottom: rem-calc(16);
      @include large {
        margin-bottom: 0;
        margin-right: rem-calc(100);
      }
    }
  }

  &__inputs {
    & > * {
      margin-bottom: rem-calc(16);
    }

    &__label {
      display: flex;
      justify-content: flex-start;
      padding-left: rem-calc(4);
      margin-bottom: rem-calc(2);
      font-size: rem-calc(14);
      color: $dark-grey;
      font-weight: 600;
    }

    &__svea {
      display: flex;
      flex-direction: column;

      &__input {
        display: flex;
        flex-direction: column;

        @include large {
          flex-direction: row;

          &__button {
            align-self: end;
            margin-left: rem-calc(10);
          }
        }

        &__button {
          margin-top: rem-calc(15);
        }
      }

      &__text {
        margin-top: rem-calc(8);
      }
    }
  }

  &__buttons {
    display: flex;
    border-bottom: rem-calc(1) solid $dark-grey;
    padding-bottom: rem-calc(20);

    & > :not(:last-child) {
      margin-right: rem-calc(16);
    }
  }
}
</style>
