import { useTable } from "./useTableNew";
import { markRaw } from "vue";
import dateHelper from "@/helpers/dateHelper";
import DomainActions from "@/components/DomainActions.vue";

export const useDomainTable = () => {
  const tableStructure = (obj) => {
    return {
      domain: obj.domain,
      organizationNumber: obj.organizationNumber,
      organizationName: obj.companyName,
      created: dateHelper.getShortDateWithTime(obj.created),
      actions: {
        component: markRaw(DomainActions),
        componentProps: {
          whitelisting: obj,
        },
        clickable: true,
      },
    };
  };

  const table = useTable(tableStructure);

  const getTableContent = (array) => {
    const tableContentArray = array.map((item, i) => {
      return {
        items: table.getTableRowFormat(item),
        id: i,
      };
    });
    return tableContentArray;
  };

  const getTableHeaders = () => {
    return [
      { name: "Domän", sortBy: "domain" },
      { name: "Org.nr", sortBy: "organizationNumber" },
      { name: "Org.namn", sortBy: "companyName" },
      { name: "Tid för vitlistning", sortBy: "created" },
      { name: "Hantera" },
    ];
  };

  const sortTable = (accounts, sortKey, order) => {
    const sortedArray = table.sortTable(accounts, sortKey, order);
    return sortedArray;
  };

  return {
    tableStructure,
    getTableHeaders,
    getTableContent,
    sortTable,
  };
};
