import {
  required,
  maxLength,
  email,
  numeric,
  minLength,
} from "@vuelidate/validators";

export default {
  phoneNumber: {
    numeric,
    maxLength: maxLength(30),
    minLength: minLength(6),
    required,
  },
  email: {
    required,
    emailFormat: email,
    maxLength: maxLength(100),
  },
};
