<template>
  <StModal size="medium" :on-close="() => emit(CLOSE)">
    <div class="st-account-approval-modal">
      <StIcon name="st-checkmark-big" />
      <StHeadline :level="2"> Godkänn konto </StHeadline>
      <p class="st-account-approval-modal__paragraph">
        Är du säker på att du vill godkänna kontot?
      </p>
      <ModalAccountDetails
        :account-name="account.accountName"
        :email="account.email"
        :organization-name="account.companyName"
      />
      <div class="st-account-approval-modal__buttons">
        <StButton full-width @click="emit(CLOSE)"> Avbryt </StButton>
        <StButton
          primary
          full-width
          @click="emit('on-approve', true)"
          :is-loading="isLoading"
        >
          Godkänn konto
        </StButton>
      </div>
      <StError v-if="errorMessage" :error-message="errorMessage" />
    </div>
  </StModal>
</template>

<script setup>
import { StModal, StButton, StHeadline, StIcon, StError } from "skanetrafiken";
import { CLOSE } from "@/constants";
import ModalAccountDetails from "../common/ModalAccountDetails.vue";

const emit = defineEmits(["close", "on-approve"]);

defineProps({
  isLoading: Boolean,
  account: Object,
  errorMessage: String,
});
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-account-approval-modal {
  text-align: center;
  font-family: var(--font-primary);
  max-width: rem-calc(600);

  & > :not(:last-child) {
    margin-bottom: rem-calc(24);
  }

  &__paragraph {
    color: var(--text-color-primary);
  }

  &__buttons {
    display: flex;

    & > :last-child {
      margin-left: rem-calc(16);
    }
  }
}
</style>
