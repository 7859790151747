<template>
  <StModal :on-close="handleClose">
    <div class="st-bo-account-name-modal">
      <div class="st-bo-account-name-modal__title">
        <StHeadline :level="2">Ändra kontonamn</StHeadline>
      </div>
      <div class="st-bo-account-name-modal__input">
        <st-form-group
          label="Kontonamn"
          :validation="v$.accountName"
          :validation-errors="validationErrors.accountName"
        >
          <st-input
            id="account-name-modal-input-name"
            type="text"
            :model-value="newValue.accountName"
            @input="(value) => handleInput(value)"
            :blur="() => handleBlur()"
            tabindex="0"
            :required="true"
            :invalid="v$ && v$.accountName && v$.accountName.$error"
          />
        </st-form-group>
      </div>
      <div class="st-bo-account-name-modal__buttons">
        <st-button full-width @click="handleClose" tabindex="0">
          Avbryt
        </st-button>
        <st-button
          primary
          full-width
          @click="handleUpdate"
          tabindex="0"
          :is-loading="isLoading"
        >
          Spara
        </st-button>
      </div>
    </div>
  </StModal>
</template>

<script>
import {
  StModal,
  StButton,
  StInput,
  StFormGroup,
  StHeadline,
} from "skanetrafiken";
import { computed, ref, onMounted } from "vue";
import { useAccount } from "@/composables/useAccount";
import ChangeAccountDetailsValidations from "@/validators/change-account-details-validations";
import ChangeAccountDetailsValidationsErrors from "@/validators/change-account-details-validations-errors";
import { useVuelidate } from "@vuelidate/core";
import { CLOSE } from "@/constants";
export default {
  name: "st-bo-account-name-modal",
  components: {
    StModal,
    StButton,
    StInput,
    StFormGroup,
    StHeadline,
  },
  props: {
    open: Boolean,
    isLoading: Boolean,
  },
  setup(_, { emit }) {
    const account = useAccount();
    const newValue = ref({ accountName: "" });

    const v$ = useVuelidate(ChangeAccountDetailsValidations, newValue);

    onMounted(() => {
      newValue.value.accountName = account.state.account.accountName;
    });

    const handleClose = () => {
      newValue.value.accountName = account.state.account.accountName;
      emit(CLOSE);
    };

    const handleBlur = () => {
      v$.value.accountName.$touch();
    };

    const handleInput = (e) => {
      newValue.value.accountName = e.target.value;
    };

    const handleUpdate = () => {
      v$.value.accountName.$touch();
      const errors = v$.value.$errors;
      if (errors.length) return;
      emit("updateAccountName", newValue.value.accountName);
    };

    return {
      account: computed(() => account.state.account),
      handleInput,
      newValue,
      handleClose,
      v$,
      handleBlur,
      validationErrors: ChangeAccountDetailsValidationsErrors,
      handleUpdate,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-account-name-modal {
  &__title {
    text-align: center;
  }

  &__input {
    text-align: left;
    margin: rem-calc(30) 0;
  }

  &__buttons {
    display: flex;
    flex-direction: row;

    & > :last-child {
      margin-left: rem-calc(10);
    }
  }
}
</style>
