<template>
  <div class="st-account-verification-actions">
    <TableButton
      :disabled="actionDisabled"
      @click="openVerificationModal"
      text="Skicka mejl"
      icon-name="st-mail-send"
    />
    <TableButton
      :disabled="actionDisabled"
      @click="openDenialModal"
      text="Ta bort"
      icon-name="st-decline"
    />
  </div>
  <AccountDenialModal
    ref="denialModal"
    :account="account"
    title="Ta bort mejlverifiering"
    description="Är du säker på att du vill ta bort mejlverifieringen? Verifieringslänken som skickats ut kommer göras ogiltlig."
    action-text="Ta bort"
    :is-loading="isLoading"
    :error-message="errorMessageDenial"
    @on-denial="handleDenial"
    @close="closeDenialModal(), (errorMessageDenial = '')"
  />
  <AccountVerificationResendModal
    ref="verificationModal"
    :account="account"
    :error-message="errorMessageResend"
    :is-loading="isLoading"
    @on-verification="handleVerification"
    @close="closeVerificationModal(), (errorMessageResend = '')"
  />
  <AccountConfirmationModal
    ref="confirmationModal"
    :account="account"
    :title="confirmationInfo.title"
    :description="confirmationInfo.description"
    @close="handleConfirmationClose"
  />
</template>
<script setup>
import { computed, ref } from "vue";
import { useModal } from "@/composables/useModal";
import { useUser } from "@/composables/useUser";
import AccountDenialModal from "./modals/AccountDenialModal.vue";
import AccountVerificationResendModal from "./modals/AccountVerificationResendModal.vue";
import AccountConfirmationModal from "./modals/AccountConfirmationModal.vue";
import Services from "@/services";
import { GENERIC_ERROR_MSG } from "@/constants";
import TableButton from "./common/TableButton.vue";
import { useAccount } from "@/composables/useAccount";

const props = defineProps({
  account: Object,
});

const confirmationInfo = ref({
  title: "",
  description: "",
  removeAfter: false,
});

const isLoading = ref(false);

const errorMessageDenial = ref("");
const errorMessageResend = ref("");

const denialModal = ref(null);
const verificationModal = ref(null);
const confirmationModal = ref(null);

const [openDenialModal, closeDenialModal] = useModal(denialModal);
const [openVerificationModal, closeVerificationModal] =
  useModal(verificationModal);
const [openConfirmationModal, closeConfirmationModal] =
  useModal(confirmationModal);

const user = useUser();
const accountHandler = useAccount();

const actionDisabled = computed(() => !user.state.isAdmin);

const handleDenial = async () => {
  isLoading.value = true;
  errorMessageDenial.value = "";
  try {
    await Services.Account.DELETE.DELETE_EMAIL_TICKET(props.account.id);
    closeDenialModal();
    confirmationInfo.value = {
      title: "Mejlverifieringen är borttagen",
      description: "",
      removeAfter: true,
    };
    openConfirmationModal();
  } catch (err) {
    errorMessageDenial.value =
      err.response?.status === 404
        ? "Denna mejlverifiering finns inte längre"
        : GENERIC_ERROR_MSG;
  } finally {
    isLoading.value = false;
  }
};

const handleVerification = async () => {
  isLoading.value = true;
  errorMessageResend.value = "";
  try {
    const newId = await Services.Account.POST.RESEND_EMAIL_TICKET(
      props.account.id
    );
    accountHandler.updateAccountVerificationId(props.account.id, newId);
    closeVerificationModal();
    confirmationInfo.value = {
      title: "Verifieringsmejl skickat",
      description: "Ett nytt verifieringsmejl har skickats till mejladressen.",
      removeAfter: false,
    };
    openConfirmationModal();
  } catch (err) {
    errorMessageResend.value =
      err.response?.status === 404
        ? "Denna mejlverifiering finns inte längre"
        : GENERIC_ERROR_MSG;
  } finally {
    isLoading.value = false;
  }
};

const handleConfirmationClose = async () => {
  closeConfirmationModal();
  if (confirmationInfo.value.removeAfter) {
    accountHandler.removeAccountVerification(props.account.id);
  }
};
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-account-verification-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include large {
    flex-direction: row;
  }

  & > :not(:last-child) {
    margin-right: rem-calc(8);
  }
}
</style>
