export default {
  domain: [
    {
      key: "required",
      message: "Domän är obligatoriskt.",
      id: "domainRequired",
    },
    {
      key: "format",
      message:
        "Felaktigt format på domän. Vänligen ange en domän som börjar med @ och som innehåller en toppdomän med minst två tecken. Inga versaler.",
      id: "domainFormat",
    },
    {
      key: "maxLength",
      message: "Domänen kan max innehålla 50 tecken",
      id: "domainMaxLength",
    },
  ],
  organizationNumber: [
    {
      key: "required",
      message: "Organisationsnummer är obligatoriskt.",
      id: "organizationNbrRequired",
    },
    {
      key: "format",
      message:
        "Felaktigt format på organisationsnummer. Vänligen ange ett nummer enligt formatet xxxxxx-xxxx eller xxxxxxxxxx.",
      id: "organizationNbrFormat",
    },
    {
      key: "existingOrganization",
      message:
        "Vi hittade inget konto registrerat hos oss med det organisationsnumret. Vänligen testa ett annat organisationsnummer.",
      id: "existingOrganization",
    },
  ],
};
