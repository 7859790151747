<template>
  <StModal size="medium" :on-close="() => $emit(CLOSE)">
    <div class="st-bo-add-traveller-modal">
      <div class="st-bo-add-traveller-modal__title">
        <StHeadline :level="2">Lägg till resenär</StHeadline>
      </div>
      <p class="st-bo-add-traveller-modal__paragraph">
        För att skapa en resenär måste du ange ett mobilnummer och/eller
        reskortsnummer samt ett namn.
      </p>
      <div class="st-bo-add-traveller-modal__inputs">
        <st-form-group
          label="Namn"
          required
          :validation="v$.name"
          :validation-errors="validationErrors.name"
        >
          <st-input
            id="add-traveller-modal-input-name"
            v-model="traveller.name"
            type="text"
            placeholder="Namn"
            tabindex="0"
            :invalid="v$ && v$.name && v$.name.$error"
            required
            :blur="() => handleBlur('name')"
          />
        </st-form-group>
        <st-form-group
          label="Mobilnummer"
          :validation="v$.phoneNumber"
          :validation-errors="validationErrors.phoneNumber"
        >
          <st-input
            id="add-traveller-modal-input-phone"
            v-model="traveller.phoneNumber"
            type="text"
            placeholder="Mobilnummer"
            tabindex="0"
            :invalid="v$ && v$.phoneNumber && v$.phoneNumber.$error"
            :blur="() => handleBlur('phoneNumber')"
          />
        </st-form-group>
        <st-form-group
          label="Reskortsnummer"
          :validation="v$.cardNumber"
          :validation-errors="validationErrors.cardNumber"
        >
          <st-input
            id="add-traveller-modal-input-card"
            v-model="traveller.cardNumber"
            type="text"
            placeholder="Reskortsnummer"
            tabindex="0"
            :invalid="v$ && v$.cardNumber && v$.cardNumber.$error"
            :blur="() => handleBlur('cardNumber')"
          />
        </st-form-group>
      </div>
      <div class="st-bo-add-traveller-modal__buttons">
        <st-button full-width @click="$emit(CLOSE)" tabindex="0">
          Avbryt
        </st-button>
        <st-button
          primary
          full-width
          @click="$emit('add', traveller)"
          tabindex="0"
          :disabled="v$.$invalid"
          :is-loading="isLoading"
        >
          Lägg till
        </st-button>
      </div>
    </div>
  </StModal>
</template>

<script>
import {
  StModal,
  StButton,
  StInput,
  StFormGroup,
  StHeadline,
} from "skanetrafiken";
import { ref } from "vue";
import AddTravellerValidations from "@/validators/add-traveller-validations";
import AddTravellerValidationsErrors from "@/validators/add-traveller-validations-errors";
import { useVuelidate } from "@vuelidate/core";
import { CLOSE } from "@/constants";
export default {
  name: "st-bo-add-traveller-modal",
  components: {
    StModal,
    StButton,
    StInput,
    StFormGroup,
    StHeadline,
  },
  props: {
    isLoading: Boolean,
  },
  setup() {
    const traveller = ref({ name: "", cardNumber: "", phoneNumber: "" });
    const v$ = useVuelidate(AddTravellerValidations, traveller);

    const handleBlur = (prop) => {
      v$.value[prop].$touch();
    };

    return {
      traveller,
      validationErrors: AddTravellerValidationsErrors,
      v$,
      handleBlur,
      CLOSE,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-add-traveller-modal {
  &__title {
    text-align: center;
  }

  &__paragraph {
    margin-top: rem-calc(15);
    text-align: center;
    color: $dark-grey;
  }

  &__inputs {
    margin: rem-calc(30) 0;

    & > :not(:last-child) {
      margin-bottom: rem-calc(30);
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;

    & > :last-child {
      margin-left: rem-calc(10);
    }
  }
}
</style>
