<template>
  <div class="st-empty-search" aria-live="polite" :aria-atomic="true">
    <StIcon :name="isQueue ? 'st-avatar-bust' : 'st-search-big'" />
    <span class="st-empty-search__title">{{ title }}</span>
    <p class="st-empty-search__desc">{{ description }}</p>
  </div>
</template>
<script setup>
import { StIcon } from "skanetrafiken";
import { computed } from "vue";

const props = defineProps({
  isQueue: Boolean,
  description: {
    type: String,
    default:
      "Vi hittade inga konton som matchade dina sökkriterier. Testa att söka med en annan sökterm eller kategori.",
  },
});

const title = computed(() =>
  props.isQueue ? "Inga kontoansökningar" : "Sökningen gav inga träffar"
);

const description = computed(() =>
  props.isQueue
    ? "Just nu finns det inga kontoansökningar som väntar på godkännande."
    : props.description
);
</script>
<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/functions.scss";
.st-empty-search {
  font-family: var(--font-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--text-color-primary);
  text-align: center;

  & > :first-child {
    margin-bottom: rem-calc(16);
  }

  & > :not(:first-child) {
    margin-top: rem-calc(16);
  }

  &__title {
    font-size: rem-calc(24);
    font-weight: 700;
    line-height: rem-calc(36);
  }

  &__desc {
    font-size: rem-calc(18);
    line-height: rem-calc(27);
    max-width: rem-calc(600);
  }
}
</style>
