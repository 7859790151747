<template>
  <div class="st-domain-actions">
    <TableButton
      :disabled="actionDisabled"
      @click="openRemoveModal"
      text="Ta bort"
      icon-name="st-decline"
    />
  </div>
  <DomainRemovalModal
    ref="removeModal"
    :whitelisting="whitelisting"
    :description="'Är du säker på att du vill neka kontot? Kontoansökan kommer tas bort ur listan och verifieringslänken som skickats ut kommer göras ogiltlig.'"
    :is-loading="isLoading"
    :error-message="errorMessage"
    @remove="removeWhitelisting"
    @close="closeRemoveModal(), (errorMessage = '')"
  />
  <DomainConfirmationModal
    ref="confirmationModal"
    title="Vitlistningen är borttagen"
    :domain="whitelisting.domain"
    :organization-name="whitelisting.companyName"
    :organization-number="whitelisting.organizationNumber"
    @close="handleConfirmationClose"
  />
</template>
<script setup>
import { computed, ref } from "vue";
import { useModal } from "@/composables/useModal";
import { useUser } from "@/composables/useUser";
import { useDomain } from "@/composables/useDomain";
import DomainRemovalModal from "@/components/modals/DomainRemovalModal.vue";
import DomainConfirmationModal from "./modals/DomainConfirmationModal.vue";
import Services from "@/services";
import { GENERIC_ERROR_MSG } from "@/constants";
import TableButton from "./common/TableButton.vue";

const props = defineProps({
  whitelisting: Object,
});

const isLoading = ref(false);

const errorMessage = ref("");

const removeModal = ref(null);
const confirmationModal = ref(null);

const [openRemoveModal, closeRemoveModal] = useModal(removeModal);
const [openConfirmationModal, closeConfirmationModal] =
  useModal(confirmationModal);

const user = useUser();
const domain = useDomain();

const actionDisabled = computed(() => !user.state.isAdmin);

const removeWhitelisting = async () => {
  isLoading.value = true;
  errorMessage.value = "";
  const payload = {
    id: props.whitelisting.id,
    createdBy: user.state.email,
  };
  try {
    await Services.Domain.DELETE.DELETE_WHITELISTING(payload);

    closeRemoveModal();
    openConfirmationModal();
  } catch (err) {
    errorMessage.value = GENERIC_ERROR_MSG;
  } finally {
    isLoading.value = false;
  }
};

const handleConfirmationClose = () => {
  domain.removeWhitelisting(props.whitelisting.id);
  closeConfirmationModal();
};
</script>
<style lang="scss" scoped>
.st-domain-actions {
  display: flex;
}
</style>
