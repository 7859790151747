import { useTable } from "./useTableNew";
import { markRaw, ref } from "vue";
import AccountApplicationActions from "@/components/AccountApplicationActions.vue";
import AccountVerificationActions from "@/components/AccountVerificationActions.vue";
import dateHelper from "@/helpers/dateHelper";

export const useAccountTable = (type) => {
  const tableType = ref(type);

  const tableStructure = (obj) => {
    const tables = {
      createdAccount: {
        accountId: obj.accountId,
        organizationNumber: obj.organizationNumber,
        organizationName: obj.organizationName,
        accountName: obj.accountName,
        ugNumber: obj.ugNumber,
        admins: obj.numberOfAdmins,
      },
      accountApplication: {
        email: obj.email,
        organizationNumber: obj.organizationNumber,
        organizationName: obj.companyName,
        accountName: obj.accountName,
        ssn: obj.ssn,
        applicationDate: dateHelper.getShortDateWithTime(obj.applicationDate),
        actions: {
          component: markRaw(AccountApplicationActions),
          componentProps: {
            account: obj,
          },
          clickable: true,
        },
      },
      accountVerification: {
        email: obj.email,
        organizationNumber: obj.organizationNumber,
        organizationName: obj.companyName,
        accountName: obj.accountName,
        ssn: obj.ssn,
        applicationDate: dateHelper.getShortDateWithTime(obj.applicationDate),
        actions: {
          component: markRaw(AccountVerificationActions),
          componentProps: {
            account: obj,
          },
          clickable: true,
        },
      },
    };
    return tables[tableType.value];
  };

  const table = useTable(tableStructure);

  const getTableContent = (array) => {
    const tableContentArray = array.map((item, i) => {
      return {
        items: table.getTableRowFormat(item),
        id: i,
      };
    });
    return tableContentArray;
  };

  const getTableHeaders = () => {
    const headers = {
      createdAccount: [
        { name: "Kundnummer", sortBy: "accountId" },
        { name: "Org.nr", sortBy: "organizationNumber" },
        { name: "Organisationsnamn", sortBy: "organizationName" },
        { name: "Kontonamn", sortBy: "accountName" },
        { name: "UG Nummer", sortBy: "ugNumber" },
        { name: "Antal Admin", sortBy: "numberOfAdmins" },
      ],
      accountApplication: [
        { name: "Mejladress", sortBy: "email" },
        { name: "Org.nr", sortBy: "organizationNumber" },
        { name: "Org.namn", sortBy: "companyName" },
        { name: "Kontonamn", sortBy: "accountName" },
        { name: "Personnummer", sortBy: "ssn" },
        { name: "Tid för ansökan", sortBy: "applicationDate" },
        { name: "Hantera" },
      ],
      accountVerification: [
        { name: "Mejladress", sortBy: "email" },
        { name: "Org.nr", sortBy: "organizationNumber" },
        { name: "Org.namn", sortBy: "companyName" },
        { name: "Kontonamn", sortBy: "accountName" },
        { name: "Personnummer", sortBy: "ssn" },
        { name: "Tid för ansökan", sortBy: "applicationDate" },
        { name: "Hantera" },
      ],
    };
    return headers[tableType.value];
  };

  const sortTable = (accounts, sortKey, order) => {
    const sortedArray = table.sortTable(accounts, sortKey, order);
    return sortedArray;
  };

  return {
    tableStructure,
    getTableHeaders,
    getTableContent,
    sortTable,
  };
};
