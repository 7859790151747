<template>
  <StModal size="medium" :on-close="() => $emit(CLOSE, false)">
    <div class="st-bo-block-account-modal">
      <StHeadline :level="2">
        {{ actionTitle }}
        {{ blockOnOrgNumber ? "konton" : "konto" }}
      </StHeadline>
      <p class="st-bo-block-account-modal__paragraph">
        {{ infoText }}
      </p>
      <div class="st-bo-block-account-modal__buttons">
        <st-button full-width @click="$emit(CLOSE, false)" tabindex="0">
          Avbryt
        </st-button>
        <st-button
          primary
          full-width
          @click="$emit('block', blockOnOrgNumber)"
          tabindex="0"
          :is-loading="isLoading"
        >
          {{ `${actionTitle} ${blockOnOrgNumber ? "konton" : "konto"}` }}
        </st-button>
      </div>
    </div>
  </StModal>
</template>

<script>
import { StModal, StButton, StHeadline } from "skanetrafiken";
import { computed } from "vue";
import { CLOSE } from "@/constants";
export default {
  name: "st-bo-confirm-block-account-modal",
  components: {
    StModal,
    StButton,
    StHeadline,
  },
  props: {
    accountName: {
      type: String,
      default: "detta konto",
    },
    blockOnOrgNumber: Boolean,
    organizationNumber: String,
    isBlocked: Boolean,
    isLoading: Boolean,
  },
  setup(props) {
    return {
      actionTitle: computed(() => (props.isBlocked ? "Aktivera" : "Spärra")),
      CLOSE,
      infoText: computed(() =>
        props.blockOnOrgNumber
          ? `Är du säker på att du vill ${
              props.isBlocked ? "aktivera" : "spärra"
            } alla ${
              props.isBlocked ? "spärrade" : ""
            } konton under organisationsnummer ${props.organizationNumber}?`
          : `Är du säker på att du vill ${
              props.isBlocked ? "aktivera" : "spärra"
            } ${props.accountName}?`
      ),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~skanetrafiken/dist/scss/main.scss";
.st-bo-block-account-modal {
  text-align: center;

  &__paragraph {
    text-align: center;
    margin: rem-calc(40) 0;
  }

  &__buttons {
    display: flex;
    flex-direction: row;

    & > * {
      line-height: rem-calc(22);
    }

    & > :last-child {
      margin-left: rem-calc(10);
    }
  }
}
</style>
